import TRadioGroup from "vue-tailwind/dist/t-radio-group";

export default {
  component: TRadioGroup,
  props: {
    classes: {
      groupWrapper: "flex flex-col",
      label: "ml-2 text-gray-700 dark:text-gray-100 text-sm",
      input:
        "text-primary-500 transition duration-100 ease-in-out border-gray-300 shadow-sm focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
    },
    variants: {
      danger: {
        groupWrapper: "flex flex-col",
        label: "ml-2 text-danger-500 capitalize text-sm",
        input:
          "text-danger-500 transition duration-100 ease-in-out border-danger-500 shadow-sm focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
      },
      buttons: {
        groupWrapper: "flex",
        label: "",
        labelChecked: "",
        wrapper:
          "mx-1 bg-white dark:bg-gray-800 border border-gray-300 flex items-center px-4 py-2 rounded shadow-sm cursor-pointer focus:shadow-outline text-sm text-gray-700 dark:text-gray-100 hover:text-gray-500 dark:text-gray-200 leading-5 capitalize",
        wrapperChecked:
          "mx-1 bg-gray-100 dark:bg-gray-900 border border-gray-300 flex items-center px-4 py-2 rounded shadow-inner cursor-pointer focus:shadow-outline text-sm text-gray-700 dark:text-gray-100 hover:text-gray-500 dark:text-gray-200 leading-5 capitalize",
        inputWrapper: "",
        inputWrapperChecked: "",
        input: "absolute invisible",
      },
      onBoarding: {
        label: "ml-2 text-gray-500 dark:text-gray-200",
      },
      horizontal: {
        groupWrapper: "flex flex-row gap-2",
      },
    },
  },
};
