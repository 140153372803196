import TPagination from "vue-tailwind/dist/t-pagination";

export default {
  component: TPagination,
  props: {
    classes: {
      wrapper: "table border-collapse text-center bg-white dark:bg-gray-800 mx-auto shadow-sm",
      element: "w-8 h-8 border border-gray-200 table-cell hover:border-primary-50 hover:bg-primary-50",
      activeElement: "w-8 h-8 border border-gray-200 border-primary-500 table-cell hover:border-primary-600 bg-primary-500 hover:bg-primary-600",
      disabledElement: "w-8 h-8 border border-gray-200 table-cell",
      ellipsisElement: "w-8 h-8 border border-gray-200 hidden md:table-cell",
      activeButton: "w-full h-full text-white transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      disabledButton: "opacity-25 w-full h-full cursor-not-allowed transition duration-100 ease-in-out",
      button: "w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      ellipsis: "",
    },
    variants: {
      rounded: {
        wrapper: "bg-white dark:bg-gray-800 mx-auto text-center flex space-x-2",
        element: "w-8 h-8 rounded-full hover:bg-primary-50",
        activeElement: "w-8 h-8 rounded-full bg-primary-500 hover:bg-primary-600",
        disabledElement: "w-8 h-8 rounded-full",
        ellipsisElement: "w-8 h-8 rounded-full hidden md:inline",
        activeButton: "border border-primary-500 w-full h-full rounded-full text-white transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
        disabledButton: "border border-gray-200  opacity-25 w-full h-full rounded-full cursor-not-allowed transition duration-100 ease-in-out",
        button: "border border-gray-200 hover:border-primary-50 rounded-full w-full h-full transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
        ellipsis: "",
      },
    },
  },
};
