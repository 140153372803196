import TModal from "vue-tailwind/dist/t-modal";

export default {
  component: TModal,
  props: {
    fixedClasses: {
      overlay: "modal z-40 overflow-auto scrolling-touch left-0 top-0 bottom-0 right-0 w-full h-full fixed bg-opacity-50",
      wrapper: "relative mx-auto z-50 px-3 py-12 top-12",
      modal: "overflow-visible relative rounded-xl",
      body: "",
      header: "border-b p-5 rounded-t-xl uppercase text-lg tracking-normal md:font-medium md:tracking-widest",
      footer: "border-t p-5 rounded-b-xl ",
      close: "flex items-center justify-center rounded-full absolute right-0 top-0 -m-3 h-8 w-8 transition duration-100 ease-in-out focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
    },
    classes: {
      overlay: "bg-black",
      wrapper: "max-w-2xl",
      modal: "bg-white dark:bg-gray-800 shadow",
      body: "",
      header: "border-gray-100",
      footer: "bg-gray-100 dark:bg-gray-900",
      close: "bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-gray-300 hover:bg-gray-200",
      closeIcon: "fill-current h-4 w-4",
      overlayEnterClass: "opacity-0",
      overlayEnterActiveClass: "transition ease-out duration-100",
      overlayEnterToClass: "opacity-100",
      overlayLeaveClass: "opacity-100",
      overlayLeaveActiveClass: "transition ease-in duration-75",
      overlayLeaveToClass: "opacity-0",
      enterClass: "",
      enterActiveClass: "",
      enterToClass: "",
      leaveClass: "",
      leaveActiveClass: "",
      leaveToClass: "",
    },
    variants: {
      danger: {
        overlay: "bg-danger-100",
        header: "border-danger-50 text-danger-700",
        close: "bg-danger-50 text-danger-700 hover:bg-danger-200 border-danger-100 border",
        modal: "bg-white dark:bg-gray-800 border border-danger-100 shadow-lg",
        footer: "bg-danger-50",
      },
      wide: {
        overlay: "bg-black",
        wrapper: "max-w-full",
        modal: "bg-white dark:bg-gray-800 shadow",
        header: "border-gray-100",
        footer: "bg-gray-100 dark:bg-gray-900",
        close: "bg-gray-100 dark:bg-gray-900 text-gray-600 dark:text-gray-300 hover:bg-gray-200",
        closeIcon: "fill-current h-4 w-4",
        overlayEnterClass: "opacity-0",
        overlayEnterActiveClass: "transition ease-out duration-100",
        overlayEnterToClass: "opacity-100",
        overlayLeaveClass: "opacity-100",
        overlayLeaveActiveClass: "transition ease-in duration-75",
        overlayLeaveToClass: "opacity-0",
      },
      centered: {
        overlay: "w-screen h-screen flex items-center justify-center",
      },
      medium: {
        wrapper: "max-w-[75%]",
        header: "border-gray-100 normal-case",
        footer: "bg-white dark:bg-gray-800",
      },
    },
  },
};
