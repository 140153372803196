import TInputGroup from "vue-tailwind/dist/t-input-group";

export default {
  component: TInputGroup,
  props: {
    fixedClasses: {
      wrapper: "",
      label: "block",
      body: "",
      feedback: "text-sm",
      description: "text-gray-400 text-sm",
    },
    classes: {
      wrapper: "mb-6",
      label: "mb-2 font-medium text-gray-600 dark:text-gray-300",
      body: "",
      feedback: "text-gray-400",
      description: "text-gray-400",
    },
    variants: {
      danger: {
        label: "mb-2 font-medium text-danger-500",
        feedback: "text-danger-500",
      },
      success: {
        label: "mb-2 font-medium text-success-500",
        feedback: "text-success-500",
      },
      big: {
        wrapper: "flex flex-col justify-start flex-0 mr-3 last:mr-0",
        label: "uppercase text-sm tracking-normal mb-3 md:font-medium md:tracking-widest",
        body: "w-full",
      },
      "multi-item": {
        body: "w-full flex items-center",
      },
      onBoarding: {
        label: "mb-6 font-medium text-gray-600 dark:text-gray-300",
      },
    },
  },
};
