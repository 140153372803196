<template>
  <div
    class="fixed inset-x-0 top-5 z-50 mx-auto max-w-lg rounded-lg p-4 text-xl shadow-lg"
    :class="classes"
  >
    <div class="flex justify-evenly gap-2">
      <p>{{ message }}</p>
      <a
        v-if="timeout === 0"
        class="cursor-pointer font-semibold text-black dark:text-white"
        @click.prevent="action"
      >Refresh
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "TtrToast",

  props: {
    message: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "danger",
    },
    timeout: {
      type: Number,
      default: 3500,
    },
    action: {
      type: Function as unknown as () => (payload: MouseEvent) => void,
      default: () => ({}),
    },
  },

  computed: {
    classes() {
      let classes = [];
      switch (this.type) {
      case "danger":
        classes.push("bg-danger-500", "text-white");
        break;
      case "success":
        classes.push("bg-success-500", "text-navy-900 dark:text-navy-200");
        break;
      case "warning":
        classes.push("bg-warning-500", "text-gray-700 dark:text-gray-100");
        break;
      default:
        classes.push("bg-navy-900", "text-white");
        break;
      }

      return classes;
    },
  },

  mounted() {
    if (this.timeout > 0) {
      setTimeout(() => {
        this.$destroy();
        this.$el?.parentNode?.removeChild(this.$el);
      }, this.timeout);
    }
  },
});
</script>
