import TAlert from "vue-tailwind/dist/t-alert";

export default {
  component: TAlert,
  props: {
    fixedClasses: {
      wrapper: "relative flex items-center p-4 border-l-4  rounded shadow-sm mb-4",
      body: "flex-grow",
      close: "absolute relative flex items-center justify-center ml-4 shrink-0 w-6 h-6 transition duration-100 ease-in-out rounded  focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      closeIcon: "fill-current h-4 w-4",
    },
    classes: {
      wrapper: "bg-navy-50 dark:bg-navy-900 border-primary-500",
      body: "text-navy-700 dark:text-navy-200",
      close: "text-primary-500 hover:bg-navy-200",
    },
    variants: {
      danger: {
        wrapper: "bg-danger-50 dark:bg-danger-900 border-danger-500",
        body: "text-danger-700 dark:text-danger-200",
        close: "text-danger-500 hover:bg-danger-200",
      },
      warning: {
        wrapper: "bg-warning-50 dark:bg-warning-900 border-warning-500",
        body: "text-warning-700  dark:text-warning-200",
        close: "text-warning-500 hover:bg-warning-200",
      },
      success: {
        wrapper: "bg-success-50 dark:bg-success-900 border-success-500",
        body: "text-success-700 dark:text-success-200",
        close: "text-success-500 hover:bg-success-200",
      },
    },
  },
};
