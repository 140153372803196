import TDropdown from "vue-tailwind/dist/t-dropdown";

export default {
  component: TDropdown,
  props: {
    fixedClasses: {
      button: "flex items-center text-white block transition duration-100 ease-in-out rounded shadow-sm focus:border-navy-900 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap",
      wrapper: "",
      dropdownWrapper: "z-10",
      dropdown: "rounded shadow mt-1",
      enterClass: "opacity-0 scale-95",
      enterActiveClass: "transition ease-out duration-100",
      enterToClass: "opacity-100 scale-100",
      leaveClass: "opacity-100 scale-100",
      leaveActiveClass: "transition ease-in duration-75",
      leaveToClass: "opacity-0 scale-95",
    },
    classes: {
      wrapper: "inline-flex flex-col",
      button: "px-4 py-2 bg-navy-900 hover:bg-white dark:hover:bg-gray-200 dark:bg-gray-800 hover:text-navy-900 dark:text-navy-200 focus:ring-2 focus:ring-primary-900 focus:ring-opacity-50 border border-navy-900",
      dropdown: "absolute bg-white dark:bg-gray-800 w-56 left-0",
    },
    variants: {
      danger: {
        button: "px-4 py-2bg-danger-500 hover:bg-danger-600",
        dropdown: "bg-danger-50",
      },
      left: {
        button: "px-4 py-2",
        dropdown: "origin-top-right absolute right-0 rounded shadow bg-white dark:bg-gray-800 mt-1 w-max",
      },
      right: {
        button: "px-4 py-2",
        dropdown: "origin-top-left absolute left-0 rounded shadow bg-white dark:bg-gray-800 mt-1 w-max",
      },
      "rich-select": {
        button: "px-4 py-2",
        wrapper: "ml-0 mr-auto relative",
        dropdown: "absolute bg-white dark:bg-gray-800 w-full left-0",
      },
      simple: {
        button: "px-4 py-2 bg-navy-900 hover:bg-white dark:hover:bg-gray-200 dark:bg-gray-800 hover:text-navy-900 dark:text-navy-200 text-gray-700 dark:text-gray-100 shadow hover:shadow-lg border border-navy-900",
        dropdownWrapper: "relative z-10",
        dropdown: "absolute right-0 left-auto bg-white dark:bg-gray-800",
      },
      selector: {
        button: "px-4 py-2 bg-navy-900 hover:bg-white dark:hover:bg-gray-200 dark:bg-gray-800 hover:text-navy-900 dark:text-navy-200 text-white shadow hover:shadow-lg rounded-lg w-full py-0 px-1 divide-x border border-navy-900",
        dropdownWrapper: "relative z-10",
        dropdown: "absolute right-0 left-auto bg-white dark:bg-gray-800",
      },
    },
  },
};
