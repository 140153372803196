import TToggle from "vue-tailwind/dist/t-toggle";

export default {
  component: TToggle,
  props: {
    fixedClasses: {
      wrapper: "relative inline-flex shrink-0 cursor-pointer transition-colors ease-in-out duration-200",
      wrapperChecked: "relative inline-flex shrink-0 cursor-pointer transition-colors ease-in-out duration-200  border-2 border-transparent focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      wrapperDisabled: "relative inline-flex shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed",
      wrapperCheckedDisabled:
        "relative inline-flex shrink-0 cursor-pointer transition-colors ease-in-out duration-200 opacity-50 cursor-not-allowed border-2 border-transparent focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      button: "inline-block absolute translate-x-0 transition ease-in-out duration-200",
      buttonChecked: "inline-block absolute translate-x-full transition ease-in-out duration-200",
      checkedPlaceholder: "inline-block",
      uncheckedPlaceholder: "inline-block",
    },
    classes: {
      wrapper: "bg-gray-100 dark:bg-gray-900 rounded-full border-2 border-transparent focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      wrapperChecked: "bg-primary-500 rounded-full",
      wrapperDisabled: "bg-gray-100 dark:bg-gray-900 rounded-full border-2 border-transparent focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      wrapperCheckedDisabled: "bg-primary-500",
      button: "h-5 w-5 rounded-full bg-white dark:bg-gray-800 shadow flex items-center justify-center text-gray-400 text-xs",
      buttonChecked: "h-5 w-5 rounded-full bg-white dark:bg-gray-800 shadow flex items-center justify-center text-primary-500 text-xs",
      checkedPlaceholder: "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
      uncheckedPlaceholder: "rounded-full w-5 h-5 flex items-center justify-center text-gray-400 text-xs",
    },
    variants: {
      danger: {
        wrapperChecked: "bg-danger-500 rounded-full",
        wrapperCheckedDisabled: "bg-danger-500 rounded-full",
      },
      success: {
        wrapperChecked: "bg-success-500 rounded-full",
        wrapperCheckedDisabled: "bg-success-500 rounded-full",
      },
      box: {
        wrapper: "bg-gray-100 dark:bg-gray-900 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
        wrapperChecked: "bg-primary-500 rounded-sm",
        wrapperCheckedDisabled: "bg-primary-500 rounded-sm",
        button: "h-6 w-8 rounded-sm bg-white dark:bg-gray-800 shadow flex items-center justify-center text-gray-400 text-xs",
        buttonChecked: "h-6 w-8 rounded-sm  bg-white dark:bg-gray-800 shadow flex items-center justify-center text-primary-500 text-xs",
        checkedPlaceholder: "rounded-sm w-8 h-6 flex items-center justify-center text-gray-400 text-xs",
        uncheckedPlaceholder: "rounded-sm w-8 h-6 flex items-center justify-center text-gray-400 text-xs",
      },
      wideBox: {
        wrapper: "bg-gray-100 dark:bg-gray-900 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
        wrapperChecked: "bg-primary-500 rounded-sm",
        wrapperCheckedDisabled: "bg-primary-500 rounded-sm",
        button: "h-6 w-12 rounded-sm bg-white dark:bg-gray-800 shadow flex items-center justify-center text-gray-400 text-xs",
        buttonChecked: "h-6 w-12 rounded-sm  bg-white dark:bg-gray-800 shadow flex items-center justify-center text-primary-500 text-xs",
        checkedPlaceholder: "rounded-sm w-12 h-6 flex items-center justify-center text-gray-400 text-xs",
        uncheckedPlaceholder: "rounded-sm w-12 h-6 flex items-center justify-center text-gray-400 text-xs",
      },
      extraWideBox: {
        wrapper: "bg-gray-100 dark:bg-gray-900 rounded-sm border-2 border-transparent focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
        wrapperChecked: "bg-primary-500 rounded-sm",
        wrapperCheckedDisabled: "bg-primary-500 rounded-sm",
        button: "h-6 w-24 rounded-sm bg-white dark:bg-gray-800 shadow flex items-center justify-center text-gray-400 text-xs",
        buttonChecked: "h-6 w-24 rounded-sm  bg-white dark:bg-gray-800 shadow flex items-center justify-center text-primary-500 text-xs",
        checkedPlaceholder: "rounded-sm w-24 h-6 flex items-center justify-center text-gray-400 text-xs",
        uncheckedPlaceholder: "rounded-sm w-24 h-6 flex items-center justify-center text-gray-400 text-xs",
      },
    },
  },
};
