import TtrToast from "@/js/components/misc/TtrToast.vue";
import {add, addHours, startOfDay as dateFnsStartOfDay, differenceInMilliseconds, endOfToday, format, getHours, getMinutes, getSeconds, isAfter, parse, startOfToday, sub, subDays} from "date-fns";
import Vue, {VueConstructor} from "vue";

const rowHeight = 16;
const margin = 40;

type Duration = {
  years?: number;
  months?: number;
  weeks?: number;
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
};

export function todayMinusDays(days: number) {
  return subDays(startOfToday(), days);
}

export function todayEndOfDay() {
  return endOfToday();
}

export function arrayToText(array: string[]) {
  if (Array.isArray(array)) {
    if (array.length <= 2) {
      return array.join(" and ");
    } else {
      return array.slice(0, -1).join(", ") + " and " + array[array.length - 1];
    }
  }
  return array;
}

export function dayOfMonthSuffix(dayOfMonth: string) {
  const parsed = parseInt(dayOfMonth, 10);

  if (parsed % 10 === 1 && parsed !== 11) {
    return "st";
  }

  if (parsed % 10 === 2 && parsed !== 12) {
    return "nd";
  }

  if (parsed % 10 === 3 && parsed !== 13) {
    return "rd";
  }

  return "th";
}

export function secondsToTimeString(seconds: number) {
  const h = Math.trunc(seconds / 60 / 60);
  const m = Math.trunc((seconds / 60) % 60);
  const formattedH = h < 10 ? "0" + h : h.toString();
  const formattedM = m < 10 ? "0" + m : m.toString();
  return `${formattedH}h:${formattedM}m`;
}

export function convertTimeObjectToString(timeObject: {hour: number; minute: number; second: number}) {
  const h = timeObject.hour < 10 ? "0" + timeObject.hour : timeObject.hour.toString();
  const m = timeObject.minute < 10 ? "0" + timeObject.minute : timeObject.minute.toString();
  const s = timeObject.second < 10 ? "0" + timeObject.second : timeObject.second.toString();

  return `${h}:${m}:${s}`;
}

export function convertSecondsToTimeObject(seconds: number) {
  const h = Math.trunc(seconds / 60 / 60);
  const m = Math.trunc((seconds / 60) % 60);
  const s = Math.ceil(seconds % 60);
  return {hour: h, minute: m, second: s};
}

export function secondsToTimeStringShort(seconds: number) {
  const h = Math.trunc(seconds / 60 / 60);
  const m = Math.trunc((seconds / 60) % 60);
  const s = Math.ceil(seconds % 60);
  const formattedH = h < 10 ? "0" + h : h.toString();
  const formattedM = m < 10 ? "0" + m : m.toString();
  const formattedS = s < 10 ? "0" + s : s.toString();
  return `${formattedH}:${formattedM}:${formattedS}`;
}

export function minutesToTimeString(minutes: number) {
  const h = Math.trunc(minutes / 60);
  const m = minutes % 60;
  const formattedH = h < 10 ? "0" + h : h.toString();
  const formattedM = m < 10 ? "0" + m : m.toString();
  return `${formattedH}h:${formattedM}m`;
}

export function secondsPrettyFormatterThisValue(this: {value: number}) {
  return secondsToTimeFormatterShort(this.value);
}

export function secondsPrettyFormatterThisY(this: {y: number}) {
  return secondsToTimeFormatterShort(this.y);
}

export function secondsPrettyFormatterThisY60(this: {y: number}) {
  return secondsToTimeFormatterShort(this.y * 60);
}

export function startOfDayYMDFormat(date: Date, withTime = false) {
  if (withTime) {
    return format(dateFnsStartOfDay(date), "yyyy-MM-dd HH:mm:ss");
  }
  return format(dateFnsStartOfDay(date), "yyyy-MM-dd");
}

export function startOfDay(date: Date) {
  return dateFnsStartOfDay(date);
}

export function getDateLocalized(date: Date) {
  if (!date) {
    return "";
  }
  return format(date, "MMMM do yyyy");
}

export function getSecondsFromTimeString(timestring: string) {
  const split = timestring.split(":");
  const hours = parseInt(split[0] ?? "0", 10);
  const mins = parseInt(split[1] ?? "0", 10);
  const secs = parseInt(split[2] ?? "0", 10);

  return hours * 60 * 60 + mins * 60 + secs;
}

export function getWeekEndsCount(startDate: Date, endDate: Date) {
  let count = 0;
  const curDate = new Date(startDate.getTime());
  while (curDate <= endDate) {
    const dayOfWeek = curDate.getDay();
    if (dayOfWeek === 0 || dayOfWeek === 6) count++;
    curDate.setDate(curDate.getDate() + 1);
  }
  return count;
}

export function convertStringToDate(stringDate: string, format = "") {
  if (format) {
    return parse(stringDate, format, new Date());
  }
  return parse(stringDate, "yyyy-MM-dd", new Date());
}

export function convertDbDateStringToDate(stringDate: string, format = "") {
  if (format) {
    return parse(stringDate, format, new Date());
  }
  return parse(stringDate, "yyyy-MM-dd HH:mm:ss", new Date());
}

export function convertDateToString(date: Date, dateFormat = "") {
  if (dateFormat) {
    return format(date, dateFormat);
  }
  return format(date, "yyyy-MM-dd");
}

export function convertDateToDbDateString(date: Date, dateFormat = "") {
  if (dateFormat) {
    return format(date, dateFormat);
  }
  return format(date, "yyyy-MM-dd HH:mm:ss");
}

export function diffInHours(dateLeft: Date, dateRight: Date) {
  const num = differenceInMilliseconds(dateLeft, dateRight) / 1000 / 60 / 60;
  return Number.parseFloat(num.toFixed(2));
}

export function diffInDays(dateLeft: Date, dateRight: Date) {
  const num = differenceInMilliseconds(dateLeft, dateRight) / 1000 / 60 / 60 / 24;
  return Number.parseFloat(num.toFixed(2));
}

export function diffInWeeks(dateLeft: Date, dateRight: Date) {
  const num = differenceInMilliseconds(dateLeft, dateRight) / 1000 / 60 / 60 / 24 / 7;
  return Number.parseFloat(num.toFixed(2));
}

export function diffInMonths(dateLeft: Date, dateRight: Date) {
  const num = differenceInMilliseconds(dateLeft, dateRight) / 1000 / 60 / 60 / 24 / 30.4375;
  return Number.parseFloat(num.toFixed(2));
}

export function diffInYears(dateLeft: Date, dateRight: Date) {
  const num = differenceInMilliseconds(dateLeft, dateRight) / 1000 / 60 / 60 / 24 / 365.25;
  return Number.parseFloat(num.toFixed(2));
}

export function subtractFromDate(date: Date, duration: Duration) {
  return sub(date, duration);
}

export function addToDate(date: Date, duration: Duration) {
  return add(date, duration);
}

export function isDateAfter(date: Date, dateToCompare: Date) {
  return isAfter(date, dateToCompare);
}

export function startOfTodayDate() {
  return startOfToday();
}

export function diffPercent(oldVal: number | null, newVal: number | null) {
  if (newVal == null || oldVal == null) {
    return null;
  }
  let percent;
  if (newVal === 0) {
    percent = -100;
  } else if (oldVal === 0) {
    percent = 100;
  } else {
    percent = ((newVal - oldVal) / oldVal) * 100;
  }

  return Intl.NumberFormat().format(Math.round(percent)) + "%";
}

export async function copyToClipboard(textToCopy: string) {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    try {
      await navigator.clipboard.writeText(textToCopy);
      console.log("Text copied to clipboard");
      return true;
    } catch (err) {
      console.error("Failed to copy: ", err);
      return false;
    }
  } else {
    console.warn("Clipboard API not available.");
    return false;
  }
}

export function launchToast(message: string, type: string, timeout = 3500, action?: () => void) {
  const body = document.querySelector("#app");
  const mountNode = document.createElement("div");
  body?.appendChild(mountNode);
  const toastComponent: VueConstructor<Vue> = Vue.extend(TtrToast);
  const data: {message: string; type: string; timeout: number; action?: () => void} = {
    message,
    type,
    timeout,
  };

  if (typeof action === "function") {
    data.action = action;
  }

  new toastComponent({
    propsData: data,
  }).$mount(mountNode);
}

export function doItemsOverlap(previousItem: any, currentItem: any, cols: number) {
  if (previousItem === currentItem) {
    return false;
  } // same element
  if (currentItem.x + currentItem.w > cols) {
    return true;
  } //currentItem is out of bounds
  if (previousItem.x + previousItem.w <= currentItem.x) {
    return false;
  } // previousItem is left of currentItem
  if (previousItem.x >= currentItem.x + currentItem.w) {
    return false;
  } // previousItem is right of currentItem
  if (previousItem.y + previousItem.h <= currentItem.y) {
    return false;
  } // previousItem is above currentItem
  if (previousItem.y >= currentItem.y + currentItem.h) {
    return false;
  } // previousItem is below currentItem
  return true; // boxes overlap
}

export function calculatePosition(previousItem: any, currentItem: any, cols: number) {
  //start it off right next to the old item but on the same row
  let x = previousItem.x + previousItem.w;
  let y = previousItem.y;

  //if the new x + width larger than the total cols, we need to move it on to the next line
  if (x + currentItem.w > cols) {
    y = previousItem.y + previousItem.h;
    x = 0;
  }
  return [x, y];
}

export function calculateMinHeight(isExpanding: boolean, element: HTMLElement) {
  const targetH = isExpanding ? element.scrollHeight : element.firstElementChild?.scrollHeight || 0;
  let newH = 0;
  while (Math.round(rowHeight * newH + Math.max(0, newH - 1) * margin) < targetH) {
    newH++;
  }

  return newH;
}

export function formatSecondsToTime(seconds: number) {
  return secondsToTimeFormatter(seconds);
}

export function secondsToTimeFormatterShort(secondsAmount: number): string {
  const SECONDS_PER_DAY = 86400;
  const MINUTES_IN_HOUR = 60;

  const days = Math.floor(secondsAmount / SECONDS_PER_DAY);
  const hours = Math.floor((secondsAmount % SECONDS_PER_DAY) / (MINUTES_IN_HOUR * MINUTES_IN_HOUR));
  const minutes = Math.floor((secondsAmount % (MINUTES_IN_HOUR * MINUTES_IN_HOUR)) / MINUTES_IN_HOUR);
  const seconds = secondsAmount % MINUTES_IN_HOUR;

  if (days > 0) {
    return `${days}d:${hours}h`;
  }
  if (hours > 0) {
    if (minutes === 0) {
      return `${hours}h`;
    }
    return `${hours}h:${minutes}m`;
  }
  if (seconds === 0) {
    return `${minutes}m`;
  }
  return `${minutes}m:${seconds}s`;
}

export function secondsToTimeFormatter(secondsAmount: number) {
  const normalizeTime = (time: string) => (time.length === 1 ? `0${time}` : time);

  const SECONDS_PER_DAY = 86400;
  const SECONDS_TO_MILLISECONDS_COEFF = 1000;
  const MINUTES_IN_HOUR = 60;

  const milliseconds = secondsAmount * SECONDS_TO_MILLISECONDS_COEFF;

  const date = new Date(milliseconds);
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR;
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff);

  const days = Math.floor(secondsAmount / SECONDS_PER_DAY);
  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)));
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)));
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)));

  const hoursOutput = `${hours}`;

  const timeString = `${hoursOutput}h:${minutes}m:${seconds}s`;

  if (days > 0) {
    return `${days}d ${timeString}`;
  }

  return timeString;
}

const isNumber = (n: any): boolean => {
  return !Number.isNaN(parseFloat(n)) && !Number.isNaN(n - 0);
};

export const formatValue = (value: any, field: string): string => {
  if (value === null || value === "") {
    return "N/A";
  }
  if (isNumber(value)) {
    value = Intl.NumberFormat().format(parseFloat(value));
  }
  if ((field.includes("percent") || field.includes("rate")) && isNumber(value)) {
    value += "%";
  }
  return value;
};

export function secondsToHoursTimeFormatterShort(secondsAmount: number): string {
  const SECONDS_PER_HOUR = 3600;
  const MINUTES_IN_HOUR = 60;

  const hours = Math.floor(secondsAmount / SECONDS_PER_HOUR);
  const minutes = Math.floor((secondsAmount % SECONDS_PER_HOUR) / MINUTES_IN_HOUR);
  const seconds = secondsAmount % MINUTES_IN_HOUR;

  if (hours > 0) {
    if (minutes === 0) {
      return `${hours}h`;
    }
    return `${hours}h:${minutes}m`;
  }
  if (seconds === 0) {
    return `${minutes}m`;
  }
  return `${minutes}m:${seconds}s`;
}

export const isLaravelLengthAwarePaginator = (obj: any): obj is LaravelLengthAwarePaginator<any> => {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.current_page === "number" &&
    Array.isArray(obj.data) &&
    typeof obj.first_page_url === "string" &&
    (typeof obj.from === "number" || obj.from === null) &&
    typeof obj.last_page === "number" &&
    typeof obj.last_page_url === "string" &&
    Array.isArray(obj.links) &&
    (typeof obj.next_page_url === "string" || obj.next_page_url === null) &&
    typeof obj.path === "string" &&
    typeof obj.per_page === "number" &&
    (typeof obj.prev_page_url === "string" || obj.prev_page_url === null) &&
    (typeof obj.to === "number" || obj.to === null) &&
    typeof obj.total === "number" &&
    (typeof obj.failed === "undefined" || (Array.isArray(obj.failed) && obj.failed.every((item: any) => typeof item === "number")))
  );
};
