/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// noinspection ES6CheckImport
import Settings from "@/js/vue-tailwind/settings/index";
import "es6-promise/auto";
import Highcharts from "highcharts";
import HighchartsVue from "highcharts-vue";
import VTooltip from "v-tooltip";
import Vue from "vue";
import VueTailwind from "vue-tailwind";
import {ZiggyVue} from "ziggy-js";

import HighchartsMore from "highcharts/highcharts-more";
import LoadAccessibility from "highcharts/modules/accessibility";
import SolidGauge from "highcharts/modules/solid-gauge";

//Ttr Stuff
//no need for dynamic on this one as it's loaded on EVERY page basically
import "@/js/bootstrap";
import TtrAlert from "@/js/components/misc/TtrAlert.vue";
import store from "@/js/store/index";

LoadAccessibility(Highcharts);
HighchartsMore(Highcharts);
SolidGauge(Highcharts);

Vue.use(VueTailwind, Settings);
Vue.use(VTooltip, {
  placement: "top",
  delay: 0,
  offset: [0, 0],
  // container: 'body',
  // boundary: undefined,
  popperOptions: {},
  autoHide: true,
  disposeTimeout: 5000,
  themes: {
    tooltip: {
      html: false,
      triggers: ["hover", "focus"],
      hideTriggers: (triggers: Array<string>) => [...triggers, "click"],
      loadingContent: "...",
      modifiers: [
        {name: "arrow", options: {padding: 8}},
        {
          name: "preventOverflow",
          options: {
            padding: 4,
          },
        },
      ],
      offset: [0, 8],
    },
    "success-tooltip": {
      $extend: "tooltip",
      $resetCss: true,
    },
    "warn-tooltip": {
      $extend: "tooltip",
      $resetCss: true,
    },
    "danger-tooltip": {
      $extend: "tooltip",
      $resetCss: true,
    },
    "optimizer-tooltip": {
      $extend: "tooltip",
      $resetCss: true,
    },
    dropdown: {
      placement: "bottom",
      delay: 0,
      triggers: ["click"],
      offset: [0, 0],
      container: "body",
      boundary: undefined,
      autoHide: false,
      handleResize: true,
      popperOptions: {},
    },
    menu: {
      $extend: "dropdown",
      triggers: ["hover", "focus"],
      popperTriggers: ["hover", "focus"],
      delay: {
        show: 0,
        hide: 400,
      },
    },
  },
});

const TtrAccessTokens = () => import("@/js/components/accessTokens/TtrAccessTokens.vue");
const TtrActionLink = () => import("@/js/components/misc/TtrActionLink.vue");
const TtrAddThreadsToDealModal = () => import("@/js/components/reports/sales/TtrAddThreadsToDealModal.vue");
const TtrAdminOptimizerAnalytics = () => import("@/js/components/admin/tracking/TtrAdminOptimizerAnalytics.vue");
const TtrAlertConversationsListByAgent = () => import("@/js/components/alerts/TtrAlertConversationsListByAgent.vue");
const TtrAlertConversationsModal = () => import("@/js/components/alerts/TtrAlertConversationsModal.vue");
const TtrAlertsList = () => import("@/js/components/alerts/TtrAlertsList.vue");
const TtrIntlCurrencyFormat = () => import("@/js/components/misc/TtrIntlCurrencyFormat.vue");
const TtrAdminAgentsList = () => import("@/js/components/admin/TtrAdminAgentsList.vue");
const TtrAdminCompaniesList = () => import("@/js/components/admin/TtrAdminCompaniesList.vue");
const TtrAdminTracking = () => import("@/js/components/admin/TtrAdminTracking.vue");
const TtrAdminUsersList = () => import("@/js/components/admin/TtrAdminUsersList.vue");
const TtrB2BSaasReport = () => import("@/js/components/admin/TtrB2BSaasReport.vue");
const TtrAdvancedFilters = () => import("@/js/components/filtering/TtrAdvancedFilters.vue");
const TtrAgentOptimizerData = () => import("@/js/components/optimizer/TtrAgentOptimizerData.vue");
const TtrAgentsList = () => import("@/js/components/agents/TtrAgentsList.vue");
const TtrCustomersList = () => import("@/js/components/customers/TtrCustomersList.vue");
const TtrBlockReport = () => import("@/js/components/misc/TtrBlockReport.vue");
const TtrBulkAgentSelect = () => import("@/js/components/agents/TtrBulkAgentSelect.vue");
const TtrBulkAgentSelectGmail = () => import("@/js/components/agents/TtrBulkAgentSelectGmail.vue");
const TtrBulkAgentSelectMimecastSlow = () => import("@/js/components/agents/TtrBulkAgentSelectMimecastSlow.vue");
const TtrBusinessHoursBox = () => import("@/js/components/companies/TtrBusinessHoursBox.vue");
const TtrButton = () => import("@/js/components/misc/TtrButton.vue");
const TtrChooseBilling = () => import("@/js/components/billing/TtrChooseBilling.vue");
const TtrCollapse = () => import("@/js/components/misc/TtrCollapse.vue");
const TtrCrmAddActiveCampaignCredentials = () => import("@/js/components/crms/TtrCrmAddActiveCampaignCredentials.vue");
const TtrCrmAddMaropostCredentials = () => import("@/js/components/crms/TtrCrmAddMaropostCredentials.vue");
const TtrCrmMapping = () => import("@/js/components/crms/TtrCrmMapping.vue");
const TtrCustomerGroupsList = () => import("@/js/components/customerGroups/TtrCustomerGroupsList.vue");
const TtrListDeals = () => import("@/js/components/sales/deals/TtrListDeals.vue");
const TtrDealStagesList = () => import("@/js/components/sales/dealStages/TtrDealStagesList.vue");
const TtrDealStagesSetup = () => import("@/js/components/sales/auth/TtrDealStagesSetup.vue");
const TtrDeleteAccountButton = () => import("@/js/components/users/TtrDeleteAccountButton.vue");
const TtrDeleteCompanyButton = () => import("@/js/components/users/TtrDeleteCompanyButton.vue");
const TtrDeleteGroupMailboxButton = () => import("@/js/components/groupMailboxes/TtrDeleteGroupMailboxButton.vue");
const TtrDeleteTeamButton = () => import("@/js/components/teams/TtrDeleteTeamButton.vue");
const TtrDeleteDealStageButton = () => import("@/js/components/sales/dealStages/TtrDeleteDealStageButton.vue");
const TtrDomainFilters = () => import("@/js/components/filtering/TtrDomainFilters.vue");
const TtrDownloadExcelButton = () => import("@/js/components/downloadButtons/TtrDownloadExcelButton.vue");
const TtrDownloadHybridButton = () => import("@/js/components/downloadButtons/TtrDownloadHybridButton.vue");
const TtrDownloadPdfButton = () => import("@/js/components/downloadButtons/TtrDownloadPdfButton.vue");
const TtrEditOptimizerSettings = () => import("@/js/components/optimizer/TtrEditOptimizerSettings.vue");
const TtrOptimizerSetupPreview = () => import("@/js/components/optimizer/TtrOptimizerSetupPreview.vue");
const TtrEditOptimizerCriteria = () => import("@/js/components/optimizer/TtrEditOptimizerCriteria.vue");
const TtrEditAgent = () => import("@/js/components/agents/TtrEditAgent.vue");
const TtrEditAlert = () => import("@/js/components/alerts/TtrEditAlert.vue");
const TtrEditCloseEmailAddresses = () => import("@/js/components/companies/TtrEditCloseEmailAddresses.vue");
const TtrEditConversationThreadingStyle = () => import("@/js/components/companies/TtrEditConversationThreadingStyle.vue");
const TtrEditCustomerGroup = () => import("@/js/components/customerGroups/TtrEditCustomerGroup.vue");
const TtrEditCustomerGroupCrm = () => import("@/js/components/customerGroups/TtrEditCustomerGroupCrm.vue");
const TtrEditCustomerGroupHubspot = () => import("@/js/components/customerGroups/TtrEditCustomerGroupHubspot.vue");
const TtrEditCustomerGroupSalesforce = () => import("@/js/components/customerGroups/TtrEditCustomerGroupSalesforce.vue");
const TtrEditCustomerGroupZoho = () => import("@/js/components/customerGroups/TtrEditCustomerGroupZoho.vue");
const TtrEditDeal = () => import("@/js/components/sales/deals/TtrEditDeal.vue");
const TtrEditEwsAgent = () => import("@/js/components/agents/TtrEditEwsAgent.vue");
const TtrEditGroupMailbox = () => import("@/js/components/groupMailboxes/TtrEditGroupMailbox.vue");
const TtrEditInternalDomains = () => import("@/js/components/companies/TtrEditInternalDomains.vue");
const TtrEditLeadSource = () => import("@/js/components/sales/leadSources/TtrEditLeadSource.vue");
const TtrEditDealStage = () => import("@/js/components/sales/dealStages/TtrEditDealStage.vue");
const TtrEditManualSubscription = () => import("@/js/components/admin/TtrEditManualSubscription.vue");
const TtrEditMicrosoftProxyAddress = () => import("@/js/components/microsoftProxyAddresses/TtrEditMicrosoftProxyAddress.vue");
const TtrEditPagesPermissions = () => import("@/js/components/companies/TtrEditPagesPermissions.vue");
const TtrEditPromotion = () => import("@/js/components/promotions/TtrEditPromotion.vue");
const TtrEditReportSchedule = () => import("@/js/components/reports/TtrEditReportSchedule.vue");
const TtrEditTeam = () => import("@/js/components/teams/TtrEditTeam.vue");
const TtrEditUser = () => import("@/js/components/users/TtrEditUser.vue");
const TtrEmailUsernameFilters = () => import("@/js/components/filtering/TtrEmailUsernameFilters.vue");
const TtrFirstRunModals = () => import("@/js/components/reports/TtrFirstRunModals.vue");
const TtrFooterBanner = () => import("@/js/components/misc/TtrFooterBanner.vue");
const TtrGoogleBulkAddDescriptionModal = () => import("@/js/components/auth/TtrGoogleBulkAddDescriptionModal.vue");
const TtrIgniteSetup = () => import("@/js/components/sales/auth/TtrIgniteSetup.vue");
const TtrIngestionProgressBar = () => import("@/js/components/misc/TtrIngestionProgressBar.vue");
const TtrInviteAgent = () => import("@/js/components/agents/TtrInviteAgent.vue");
const TtrInstallOptimizerModal = () => import("@/js/components/agents/TtrInstallOptimizerModal.vue");
const TtrJoinCompanyRequestDropdown = () => import("@/js/components/users/TtrJoinCompanyRequestDropdown.vue");
const TtrLeaveCalendarDay = () => import("@/js/components/companies/TtrLeaveCalendarDay.vue");
const TtrLeaveDateRange = () => import("@/js/components/companies/TtrLeaveDateRange.vue");
const TtrLinkMailbox = () => import("@/js/components/agents/TtrLinkMailbox.vue");
const TtrLogsAudits = () => import("@/js/components/logs/TtrLogsAudits.vue");
const TtrLogsConversations = () => import("@/js/components/logs/TtrLogsConversations.vue");
const TtrLogsMessages = () => import("@/js/components/logs/TtrLogsMessages.vue");
const TtrManualSubscriptionsList = () => import("@/js/components/admin/TtrManualSubscriptionsList.vue");
const TtrMaxReplyTimeFilters = () => import("@/js/components/filtering/TtrMaxReplyTimeFilters.vue");
const TtrMimecastAuthForm = () => import("@/js/components/auth/TtrMimecastAuthForm.vue");
const TtrNavbar = () => import("@/js/components/misc/TtrNavbar.vue");
const TtrOAuthClients = () => import("@/js/components/accessTokens/TtrOAuthClients.vue");
const TtrOnboardingSummary = () => import("@/js/components/agents/TtrOnboardingSummary.vue");
const TtrPendingJoinRequest = () => import("@/js/components/auth/TtrPendingJoinRequest.vue");
const TtrPromptToInstallOptimizerModal = () => import("@/js/components/users/TtrPromptToInstallOptimizerModal.vue");
const TtrPromptToRedirectModal = () => import("@/js/components/users/TtrPromptToRedirectModal.vue");
const TtrRemoveUserButton = () => import("@/js/components/users/TtrRemoveUserButton.vue");
const TtrReplyTimeGoals = () => import("@/js/components/filtering/TtrReplyTimeGoals.vue");
const TtrReportActions = () => import("@/js/components/filtering/TtrReportActions.vue");
const TtrReportActionsViews = () => import("@/js/components/filtering/TtrReportActionsViews.vue");
const TtrReportDateRange = () => import("@/js/components/filtering/TtrReportDateRange.vue");
const TtrReportsComparative = () => import("@/js/components/reports/TtrReportsComparative.vue");
const TtrReportsCustomer = () => import("@/js/components/reports/TtrReportsCustomer.vue");
const TtrReportsGroupMailbox = () => import("@/js/components/reports/TtrReportsGroupMailbox.vue");
const TtrReportsInteractions = () => import("@/js/components/reports/TtrReportsInteractions.vue");
const TtrReportsOverview = () => import("@/js/components/reports/TtrReportsOverview.vue");
const TtrReportsProductivity = () => import("@/js/components/reports/TtrReportsProductivity.vue");
const TtrReportsSla = () => import("@/js/components/reports/TtrReportsSla.vue");
const TtrReportsTeam = () => import("@/js/components/reports/TtrReportsTeam.vue");
const TtrReportsTrend = () => import("@/js/components/reports/TtrReportsTrend.vue");
const TtrReportsLeadSources = () => import("@/js/components/reports/sales/TtrReportsLeadSources.vue");
const TtrReportsSalesRep = () => import("@/js/components/reports/sales/TtrReportsSalesRep.vue");
const TtrResellerAgentsList = () => import("@/js/components/reseller/TtrResellerAgentsList.vue");
const TtrResellerUsersList = () => import("@/js/components/reseller/TtrResellerUsersList.vue");
const TtrScheduledReportsList = () => import("@/js/components/scheduledReports/TtrScheduledReportsList.vue");
const TtrViewsList = () => import("@/js/components/views/TtrViewsList.vue");
const TtrSelectCompanyToJoin = () => import("@/js/components/auth/TtrSelectCompanyToJoin.vue");
const TtrSidebar = () => import("@/js/components/misc/TtrSidebar.vue");
const TtrStatLogModal = () => import("@/js/components/reports/TtrStatLogModal.vue");
const TtrSuccessSetup = () => import("@/js/components/auth/TtrSuccessSetup.vue");
const TtrTabItem = () => import("@/js/components/misc/TtrTabItem.vue");
const TtrTabsAttached = () => import("@/js/components/misc/TtrTabsAttached.vue");
const TtrThreadFilters = () => import("@/js/components/filtering/TtrThreadFilters.vue");
const TtrThreadFiltersPdf = () => import("@/js/components/filtering/TtrThreadFiltersPdf.vue");
const TtrTimeZone = () => import("@/js/components/misc/TtrTimeZone.vue");
const TtrTooltip = () => import("@/js/components/misc/TtrTooltip.vue");
const TtrTrialCompletionStatus = () => import("@/js/components/users/TtrTrialCompletionStatus.vue");
const TtrTwoFactorChallenge = () => import("@/js/components/auth/TtrTwoFactorChallenge.vue");
const TtrUploadContactsCsv = () => import("@/js/components/customers/TtrUploadContactsCsv.vue");
const TtrUploadTeamCsv = () => import("@/js/components/teams/TtrUploadTeamCsv.vue");
const TtrUsersList = () => import("@/js/components/users/TtrUsersList.vue");
const TtrUserViewInvitationDropdown = () => import("@/js/components/users/TtrUserViewInvitationDropdown.vue");
const TtrVerifyPhone = () => import("@/js/components/auth/TtrVerifyPhone.vue");
const TtrViewablePasswordFormInput = () => import("@/js/components/misc/TtrViewablePasswordFormInput.vue");
const TtrWebNotification = () => import("@/js/components/misc/TtrWebNotification.vue");

const TtrEditCalendarSyncSetting = () => import("@/js/components/calendarSyncSettings/TtrEditCalendarSyncSetting.vue");

Vue.use(HighchartsVue, {Highcharts});

//Alerts and Notifications
Vue.component("TtrAlert", TtrAlert);
Vue.component("TtrActionLink", TtrActionLink);
Vue.component("TtrIntlCurrencyFormat", TtrIntlCurrencyFormat);
Vue.component("TtrTooltip", TtrTooltip);
Vue.component("TtrIngestionProgressBar", TtrIngestionProgressBar);

//logs
Vue.component("TtrLogsConversations", TtrLogsConversations);
Vue.component("TtrLogsMessages", TtrLogsMessages);
Vue.component("TtrLogsAudits", TtrLogsAudits);
Vue.component("TtrAlertConversationsListByAgent", TtrAlertConversationsListByAgent);

//Setup
Vue.component("TtrFirstRunModals", TtrFirstRunModals);
Vue.component("TtrBusinessHoursBox", TtrBusinessHoursBox);
Vue.component("TtrEditAgent", TtrEditAgent);
Vue.component("TtrEditEwsAgent", TtrEditEwsAgent);
Vue.component("TtrBulkAgentSelect", TtrBulkAgentSelect);
Vue.component("TtrBulkAgentSelectGmail", TtrBulkAgentSelectGmail);
Vue.component("TtrEditTeam", TtrEditTeam);
Vue.component("TtrFooterBanner", TtrFooterBanner);
Vue.component("TtrUploadTeamCsv", TtrUploadTeamCsv);
Vue.component("TtrEditGroupMailbox", TtrEditGroupMailbox);
Vue.component("TtrEditCustomerGroup", TtrEditCustomerGroup);
Vue.component("TtrEditCustomerGroupHubspot", TtrEditCustomerGroupHubspot);
Vue.component("TtrEditCustomerGroupCrm", TtrEditCustomerGroupCrm);
Vue.component("TtrEditCustomerGroupSalesforce", TtrEditCustomerGroupSalesforce);
Vue.component("TtrEditCustomerGroupZoho", TtrEditCustomerGroupZoho);
Vue.component("TtrEditAlert", TtrEditAlert);
Vue.component("TtrEditMicrosoftProxyAddress", TtrEditMicrosoftProxyAddress);
Vue.component("TtrAgentsList", TtrAgentsList);
Vue.component("TtrCustomersList", TtrCustomersList);
Vue.component("TtrCustomerGroupsList", TtrCustomerGroupsList);
Vue.component("TtrEditUser", TtrEditUser);
Vue.component("TtrUserViewInvitationDropdown", TtrUserViewInvitationDropdown);
Vue.component("TtrGoogleBulkAddDescriptionModal", TtrGoogleBulkAddDescriptionModal);
Vue.component("TtrMimecastAuthForm", TtrMimecastAuthForm);
Vue.component("TtrBulkAgentSelectMimecastSlow", TtrBulkAgentSelectMimecastSlow);
Vue.component("TtrEditInternalDomains", TtrEditInternalDomains);
Vue.component("TtrEditConversationThreadingStyle", TtrEditConversationThreadingStyle);
Vue.component("TtrEditCloseEmailAddresses", TtrEditCloseEmailAddresses);
Vue.component("TtrLeaveCalendarDay", TtrLeaveCalendarDay);
Vue.component("TtrLeaveDateRange", TtrLeaveDateRange);
Vue.component("TtrAccessTokens", TtrAccessTokens);
Vue.component("TtrOAuthClients", TtrOAuthClients);
Vue.component("TtrInviteAgent", TtrInviteAgent);
Vue.component("TtrChooseBilling", TtrChooseBilling);
Vue.component("TtrTimeZone", TtrTimeZone);
Vue.component("TtrWebNotification", TtrWebNotification);
Vue.component("TtrUploadContactsCsv", TtrUploadContactsCsv);
Vue.component("TtrTrialCompletionStatus", TtrTrialCompletionStatus);
Vue.component("TtrPromptToInstallOptimizerModal", TtrPromptToInstallOptimizerModal);
Vue.component("TtrPromptToRedirectModal", TtrPromptToRedirectModal);
Vue.component("TtrLinkMailbox", TtrLinkMailbox);
Vue.component("TtrOnboardingSummary", TtrOnboardingSummary);
Vue.component("TtrSelectCompanyToJoin", TtrSelectCompanyToJoin);
Vue.component("TtrJoinCompanyRequestDropdown", TtrJoinCompanyRequestDropdown);
Vue.component("TtrPendingJoinRequest", TtrPendingJoinRequest);
Vue.component("TtrEditCalendarSyncSetting", TtrEditCalendarSyncSetting);
Vue.component("TtrAlertsList", TtrAlertsList);
Vue.component("TtrAlertConversationsModal", TtrAlertConversationsModal);
Vue.component("TtrInstallOptimizerModal", TtrInstallOptimizerModal);

//Reports
Vue.component("TtrReportDateRange", TtrReportDateRange);
Vue.component("TtrThreadFilters", TtrThreadFilters);
Vue.component("TtrThreadFiltersPdf", TtrThreadFiltersPdf);
Vue.component("TtrReportsOverview", TtrReportsOverview);
Vue.component("TtrReportsProductivity", TtrReportsProductivity);
Vue.component("TtrReportsComparative", TtrReportsComparative);
Vue.component("TtrReportsInteractions", TtrReportsInteractions);
Vue.component("TtrReportsCustomer", TtrReportsCustomer);
Vue.component("TtrReportsTrend", TtrReportsTrend);
Vue.component("TtrReportsLeadSources", TtrReportsLeadSources);
Vue.component("TtrReportsTeam", TtrReportsTeam);
Vue.component("TtrReportsGroupMailbox", TtrReportsGroupMailbox);
Vue.component("TtrReportsSla", TtrReportsSla);
Vue.component("TtrReportsSalesRep", TtrReportsSalesRep);
Vue.component("TtrEditReportSchedule", TtrEditReportSchedule);
Vue.component("TtrStatLogModal", TtrStatLogModal);
Vue.component("TtrBlockReport", TtrBlockReport);
Vue.component("TtrAgentOptimizerData", TtrAgentOptimizerData);
Vue.component("TtrAddThreadsToDealModal", TtrAddThreadsToDealModal);
Vue.component("TtrScheduledReportsList", TtrScheduledReportsList);
Vue.component("TtrViewsList", TtrViewsList);

//Filters
Vue.component("TtrAdvancedFilters", TtrAdvancedFilters);
Vue.component("TtrDomainFilters", TtrDomainFilters);
Vue.component("TtrEmailUsernameFilters", TtrEmailUsernameFilters);
Vue.component("TtrMaxReplyTimeFilters", TtrMaxReplyTimeFilters);
Vue.component("TtrReplyTimeGoals", TtrReplyTimeGoals);

//Buttons
Vue.component("TtrButton", TtrButton);
Vue.component("TtrDeleteTeamButton", TtrDeleteTeamButton);
Vue.component("TtrDeleteDealStageButton", TtrDeleteDealStageButton);
Vue.component("TtrDeleteGroupMailboxButton", TtrDeleteGroupMailboxButton);
Vue.component("TtrDeleteAccountButton", TtrDeleteAccountButton);
Vue.component("TtrDeleteCompanyButton", TtrDeleteCompanyButton);
Vue.component("TtrRemoveUserButton", TtrRemoveUserButton);
Vue.component("TtrDownloadPdfButton", TtrDownloadPdfButton);
Vue.component("TtrDownloadExcelButton", TtrDownloadExcelButton);
Vue.component("TtrDownloadHybridButton", TtrDownloadHybridButton);
Vue.component("TtrReportActions", TtrReportActions);
Vue.component("TtrReportActionsViews", TtrReportActionsViews);
Vue.component("TtrSidebar", TtrSidebar);
Vue.component("TtrNavbar", TtrNavbar);
Vue.component("TtrTabItem", TtrTabItem);
Vue.component("TtrTabsAttached", TtrTabsAttached);

//admin
Vue.component("TtrAdminUsersList", TtrAdminUsersList);
Vue.component("TtrB2BSaasReport", TtrB2BSaasReport);
Vue.component("TtrAdminAgentsList", TtrAdminAgentsList);
Vue.component("TtrAdminCompaniesList", TtrAdminCompaniesList);
Vue.component("TtrAdminTracking", TtrAdminTracking);
Vue.component("TtrAdminOptimizerAnalytics", TtrAdminOptimizerAnalytics);
Vue.component("TtrEditManualSubscription", TtrEditManualSubscription);
Vue.component("TtrEditPromotion", TtrEditPromotion);
Vue.component("TtrManualSubscriptionsList", TtrManualSubscriptionsList);

//crm
Vue.component("TtrCrmMapping", TtrCrmMapping);
Vue.component("TtrCrmAddActiveCampaignCredentials", TtrCrmAddActiveCampaignCredentials);
Vue.component("TtrCrmAddMaropostCredentials", TtrCrmAddMaropostCredentials);

//reseller
Vue.component("TtrResellerUsersList", TtrResellerUsersList);
Vue.component("TtrResellerAgentsList", TtrResellerAgentsList);

//auth
Vue.component("TtrVerifyPhone", TtrVerifyPhone);
Vue.component("TtrSuccessSetup", TtrSuccessSetup);
//two factor auth
Vue.component("TtrTwoFactorChallenge", TtrTwoFactorChallenge);

//sales
Vue.component("TtrEditLeadSource", TtrEditLeadSource);
Vue.component("TtrEditDeal", TtrEditDeal);
Vue.component("TtrEditDealStage", TtrEditDealStage);
Vue.component("TtrListDeals", TtrListDeals);
Vue.component("TtrIgniteSetup", TtrIgniteSetup);
Vue.component("TtrDealStagesList", TtrDealStagesList);
Vue.component("TtrDealStagesSetup", TtrDealStagesSetup);
//other
Vue.component("TtrViewablePasswordFormInput", TtrViewablePasswordFormInput);
Vue.component("TtrUsersList", TtrUsersList);
Vue.component("TtrEditPagesPermissions", TtrEditPagesPermissions);
Vue.component("TtrEditOptimizerSettings", TtrEditOptimizerSettings);
Vue.component("TtrOptimizerSetupPreview", TtrOptimizerSetupPreview);
Vue.component("TtrEditOptimizerCriteria", TtrEditOptimizerCriteria);
Vue.component("TtrCollapse", TtrCollapse);

const tokenMeta = document.head?.querySelector<HTMLMetaElement>('meta[name="company"]');
const company = tokenMeta ? tokenMeta.content : null;

Vue.use(ZiggyVue);

// noinspection JSUnusedLocalSymbols
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// noinspection JSUnusedLocalSymbols
// eslint-disable-next-line no-unused-vars,@typescript-eslint/no-unused-vars
const app = new Vue({
  el: "#app",
  store: store,
  created() {
    if (typeof user !== "undefined" && user !== null) {
      this.$store.commit("user/setUser", user);
    }
    if (typeof company !== "undefined" && company !== null) {
      this.$store.commit("user/setCompany", company);
    }
    if (typeof statusMessage !== "undefined" && statusMessage !== null) {
      this.$store.commit("user/setStatus", statusMessage);
    }
    if (typeof error !== "undefined" && error !== null) {
      this.$store.commit("user/setError", error);
    }
    if (typeof billingError !== "undefined" && billingError !== null) {
      this.$store.commit("user/setBillingError", billingError);
    }
    if (typeof productType !== "undefined" && productType !== null) {
      this.$store.commit("user/setProductType", productType);
    }
  },
});
