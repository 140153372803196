import general from "@/js/store/modules/general";
import optimizerSetup from "@/js/store/modules/optimizerSetup";
import overview from "@/js/store/modules/overview";
import productivity from "@/js/store/modules/productivity";
import reportSetup from "@/js/store/modules/reportSetup";
import threadFilters from "@/js/store/modules/threadFilters";
import user from "@/js/store/modules/user";
import {RootState} from "@/js/store/types";
import Vue from "vue";
import Vuex, {Store} from "vuex";

Vue.use(Vuex);

const store: Store<RootState> = new Vuex.Store({
  modules: {
    general,
    optimizerSetup,
    overview,
    productivity,
    reportSetup,
    threadFilters,
    user,
  },
});

export default store;

export const useStore = (): Store<RootState> => store;
