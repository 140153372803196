<template>
  <div
    v-if="showNotification"
    :id="$options.name"
  >
    <t-alert
      :show="isError"
      variant="danger"
      @hidden="dismiss"
    >
      <p class="mb-4">
        {{ error }}
      </p>

      <div
        v-if="hasErrors"
        class="text-left"
      >
        <div
          v-for="errorItem in errors"
          :key="errorItem.key"
          class="mb-4"
        >
          <h2 class="pb-2 text-xl">
            {{ titlecase(errorItem.key) }}:
          </h2>
          <ul class="ml-5 list-disc">
            <li
              v-for="(item, index) in errorItem.value"
              :key="index"
            >
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
    </t-alert>

    <t-alert
      :show="isBillingError"
      variant="danger"
      @hidden="dismiss"
    >
      <p class="mb-4">
        {{ billingError }}
      </p>

      <div class="flex items-center">
        <t-button :href="route('billing.choose.type')">
          <i class="fa-light fa-credit-card" />
          <span>Add A Card</span>
        </t-button>
      </div>
    </t-alert>

    <t-alert
      :show="isStatus"
      variant="success"
      @hidden="dismiss"
    >
      {{ statusToDisplay }}
    </t-alert>
  </div>
</template>

<script lang="ts">
import {lowerCase, startCase} from "lodash-es";
import {defineComponent} from "vue";

export default defineComponent({
  name: "TtrAlert",

  data: (): {dismissNotification: boolean} => {
    return {
      dismissNotification: false,
    };
  },

  computed: {
    isError() {
      return this.error != null;
    },

    hasErrors() {
      return this.errors.length > 0;
    },

    isBillingError() {
      return this.billingError != null;
    },

    isStatus() {
      return this.status != null;
    },

    showNotification() {
      if (!this.dismissNotification) {
        if (this.status != null) {
          return true;
        }
        if (this.error != null) {
          return true;
        }
        if (this.hasErrors) {
          return true;
        }
        if (this.billingError != null) {
          return true;
        }
      }
      return false;
    },
    status() {
      return this.$store.state.user.status;
    },
    error() {
      return this.$store.state.user.error;
    },
    errors() {
      return this.$store.state.user.errors;
    },
    billingError() {
      return this.$store.state.user.billingError;
    },

    statusToDisplay() {
      switch (this.status) {
      case "two-factor-authentication-enabled":
        return "Two factor authentication has been enabled";
      case "two-factor-authentication-disabled":
        return "Two factor authentication has been disabled";
      case "recovery-codes-generated":
        return "Recovery codes have been regenerated";
      default:
        return this.status;
      }
    },
  },

  methods: {
    clearStatus() {
      this.$store.commit("user/clearStatus");
    },
    titlecase(value: string) {
      return startCase(lowerCase(value));
    },
    dismiss() {
      this.clearStatus();
    },
  },
});
</script>
