import TCard from "vue-tailwind/dist/t-card";

export default {
  component: TCard,
  props: {
    fixedClasses: {
      wrapper: "border rounded-lg shadow-sm ",
      body: "p-3",
      header: "border-b p-3 rounded-t-lg",
      footer: "border-t p-3 rounded-b-lg",
    },
    classes: {
      wrapper: "bg-white dark:bg-gray-800 border-gray-200",
      body: "",
      header: "border-gray-200",
      footer: "border-gray-200",
    },
    variants: {
      danger: {
        wrapper: "bg-danger-50 text-danger-700 border-danger-300",
        header: "border-danger-400 text-danger-700",
        footer: "border-danger-400 text-danger-700",
      },
      gray: {
        wrapper: "bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-100 border-gray-300",
        header: "border-gray-400 text-gray-700 dark:text-gray-100",
        footer: "border-gray-400 text-gray-700 dark:text-gray-100",
      },
    },
  },
};
