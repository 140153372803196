import {RootState} from "@/js/store/types";
import {ActionContext, ActionTree, Module, MutationTree} from "vuex";

export type StatLogs = {
  showStatLogModal: boolean;
  statToShow: string | null;
  forcedStatsModel?: any;
  percentileRank?: any;
};

export type LoadedReport = {
  reportId: boolean;
  isSavedReport: boolean;
};

export type GeneralState = {
  showAddGroupMailboxModal: boolean;
  showAddTeamModal: boolean;
  statLogs: StatLogs;
  loadedReport: LoadedReport;
};

const state: GeneralState = {
  showAddGroupMailboxModal: false,
  showAddTeamModal: false,
  statLogs: {
    showStatLogModal: false,
    statToShow: null,
    forcedStatsModel: undefined,
    percentileRank: undefined,
  },
  loadedReport: {
    reportId: false,
    isSavedReport: false,
  },
};

export type GeneralMutations<S = GeneralState> = {
  setShowAddGroupMailboxModal(state: S, status: boolean): void;
  setShowAddTeamModal(state: S, status: boolean): void;
  setShowStatLogModal(state: S, status: boolean): void;
  setStatToShow(state: S, email: string | null): void;
  setForcedStatsModel(state: S, forcedStatsModel: any): void;
  setPercentileRank(state: S, percentileRank: any): void;
  setLoadedReport(state: S, report: GeneralState["loadedReport"]): void;
};

const mutations: MutationTree<GeneralState> & GeneralMutations = {
  setShowAddGroupMailboxModal(state, status: boolean) {
    state.showAddGroupMailboxModal = status;
  },
  setShowAddTeamModal(state, status: boolean) {
    state.showAddTeamModal = status;
  },
  setShowStatLogModal(state, status: boolean) {
    state.statLogs.showStatLogModal = status;
  },
  setStatToShow(state, email: string | null) {
    state.statLogs.statToShow = email;
  },
  setForcedStatsModel(state, forcedStatsModel: any) {
    state.statLogs.forcedStatsModel = forcedStatsModel;
  },
  setPercentileRank(state, percentileRank: any) {
    state.statLogs.percentileRank = percentileRank;
  },
  setLoadedReport(state, report: GeneralState["loadedReport"]) {
    state.loadedReport = report;
  },
};

export type GeneralActions<S = GeneralState, R = RootState> = {
  setStatLogModal(
    context: ActionContext<S, R>,
    {
      stat,
      showModal,
      forcedStatsModel,
      percentileRank,
    }: {
      stat: string | null;
      showModal: boolean;
      forcedStatsModel: any;
      percentileRank: any;
    },
  ): void;
  updateLoadedReport(context: ActionContext<S, R>, report: GeneralState["loadedReport"]): void;
};

const actions: ActionTree<GeneralState, RootState> & GeneralActions = {
  setStatLogModal(
    {commit}: ActionContext<GeneralState, RootState>,
    {
      stat,
      showModal,
      forcedStatsModel,
      percentileRank,
    }: {
      stat: string | null;
      showModal: boolean;
      forcedStatsModel: any;
      percentileRank: any;
    },
  ) {
    commit("setStatToShow", stat);
    commit("setShowStatLogModal", showModal);
    commit("setForcedStatsModel", forcedStatsModel);
    commit("setPercentileRank", percentileRank);
  },

  updateLoadedReport({commit}: ActionContext<GeneralState, RootState>, report: GeneralState["loadedReport"]) {
    commit("setLoadedReport", report);
  },
};

const general: Module<GeneralState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default general;
