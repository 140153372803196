import TDatepicker from "vue-tailwind/dist/t-datepicker";

export default {
  component: TDatepicker,
  props: {
    fixedClasses: {
      navigator: "flex",
      navigatorViewButton: "flex items-center",
      navigatorViewButtonIcon: "shrink-0 h-5 w-5",
      navigatorViewButtonBackIcon: "shrink-0 h-5 w-5",
      navigatorLabel: "flex items-center py-1",
      navigatorPrevButtonIcon: "h-6 w-6 inline-flex",
      navigatorNextButtonIcon: "h-6 w-6 inline-flex",
      inputWrapper: "relative",
      viewGroup: "inline-flex flex-wrap",
      view: "w-80",
      calendarDaysWrapper: "grid grid-cols-7",
      calendarHeaderWrapper: "grid grid-cols-7",
      monthWrapper: "grid grid-cols-4",
      yearWrapper: "grid grid-cols-4",
      input: "block w-full px-3 py-2 transition duration-100 ease-in-out focus:ring-transparent shadow-none disabled:opacity-50 disabled:cursor-not-allowed rounded",
      clearButton: "flex shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
      clearButtonIcon: "fill-current h-3 w-3",
      timepickerWrapper: "flex items-center px-4 py-2 space-x-2",
      timepickerTimeWrapper: "flex items-center space-x-2",
      timepickerTimeFieldsWrapper: "bg-gray-100 dark:bg-gray-900 rounded-md w-full text-right flex items-center border border-gray-100 focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      timepickerOkButton:
        "text-primary-600 text-sm uppercase font-semibold transition duration-100 ease-in-out border border-transparent focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 rounded cursor-pointer",
      timepickerInput:
        "text-center w-8 border-transparent bg-transparent p-0 h-6 text-sm transition duration-100 ease-in-out border border-transparent focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 rounded",
      timepickerTimeLabel: "flex-grow text-sm text-gray-500 dark:text-gray-200",
      timepickerAmPmWrapper:
        "relative inline-flex shrink-0 transition duration-200 ease-in-out bg-gray-100 dark:bg-gray-900 border border-transparent rounded cursor-pointer focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      timepickerAmPmWrapperChecked:
        "relative inline-flex shrink-0 transition duration-200 ease-in-out bg-gray-100 dark:bg-gray-900 border border-transparent rounded cursor-pointer focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50",
      timepickerAmPmWrapperDisabled: "relative inline-flex shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed",
      timepickerAmPmWrapperCheckedDisabled: "relative inline-flex shrink-0 transition duration-200 ease-in-out opacity-50 cursor-not-allowed",
      timepickerAmPmButton: "absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 dark:text-gray-50 transition duration-200 ease-in-out translate-x-0 bg-white dark:bg-gray-800 rounded shadow",
      timepickerAmPmButtonChecked: "absolute flex items-center justify-center w-6 h-6 text-xs text-gray-800 dark:text-gray-50 transition duration-200 ease-in-out translate-x-full bg-white dark:bg-gray-800 rounded shadow",
      timepickerAmPmCheckedPlaceholder: "flex items-center justify-center w-6 h-6 text-xs text-gray-500 dark:text-gray-200 rounded-sm",
      timepickerAmPmUncheckedPlaceholder: "flex items-center justify-center w-6 h-6 text-xs text-gray-500 dark:text-gray-200 rounded-sm",
    },
    classes: {
      wrapper: "flex flex-col w-full min-w-max md:w-auto md:min-w-xs",
      dropdownWrapper: "relative z-10",
      dropdown: "origin-top-left absolute rounded shadow bg-white dark:bg-gray-800 overflow-hidden mt-2",
      enterClass: "opacity-0 scale-95",
      enterActiveClass: "transition ease-out duration-100",
      enterToClass: "opacity-100 scale-100",
      leaveClass: "opacity-100 scale-100",
      leaveActiveClass: "transition ease-in duration-75",
      leaveToClass: "opacity-0 scale-95",
      inlineWrapper: "",
      inlineViews: "rounded bg-white dark:bg-gray-800 border mt-1 inline-flex",
      inputWrapper: "",
      input: "text-primary-500 placeholder-gray-400 border-none bg-white dark:bg-gray-800 rounded cursor-pointer focus:border-none",
      clearButton: "hover:bg-gray-100 dark:bg-gray-900 rounded transition duration-100 ease-in-out text-gray-600 dark:text-gray-300",
      clearButtonIcon: "",
      viewGroup: "",
      view: "",
      navigator: "pt-2 px-3",
      navigatorViewButton: "transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100 dark:bg-gray-900",
      navigatorViewButtonIcon: "fill-current text-primary-500 hover:text-primary-400",
      navigatorViewButtonBackIcon: "fill-current text-primary-500 hover:text-primary-400",
      navigatorViewButtonMonth: "text-gray-700 dark:text-gray-100 font-semibold",
      navigatorViewButtonYear: "text-gray-500 dark:text-gray-200 ml-1",
      navigatorViewButtonYearRange: "text-gray-500 dark:text-gray-200 ml-1",
      navigatorLabel: "py-1",
      navigatorLabelMonth: "text-gray-700 dark:text-gray-100 font-semibold hover:text-primary-400",
      navigatorLabelYear: "text-gray-500 dark:text-gray-200 ml-1 hover:text-primary-400",
      navigatorPrevButton: "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 dark:bg-gray-900 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed",
      navigatorNextButton: "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 dark:bg-gray-900 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed",
      navigatorPrevButtonIcon: "text-primary-500 hover:text-primary-400",
      navigatorNextButtonIcon: "text-primary-500 hover:text-primary-400",
      calendarWrapper: "px-3 pt-2",
      calendarHeaderWrapper: "",
      calendarHeaderWeekDay: "uppercase text-xs text-gray-500 dark:text-gray-200 w-12 h-12 flex items-center justify-center",
      calendarDaysWrapper: "",
      calendarDaysDayWrapper: "w-full h-12 flex shrink-0 items-center",
      otherMonthDay: "text-sm rounded-full w-12 h-12 mx-auto hover:bg-primary-50 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed",
      emptyDay: "",
      inRangeFirstDay: "text-sm bg-primary-500 text-white w-full h-9 rounded-l-full",
      inRangeLastDay: "text-sm bg-primary-500 text-white w-full h-9 rounded-r-full",
      inRangeDay: "text-sm bg-primary-300 w-full h-9 disabled:opacity-50 disabled:cursor-not-allowed",
      selectedDay: "text-sm rounded-full w-12 h-12 mx-auto bg-primary-500 text-white disabled:opacity-50 disabled:cursor-not-allowed",
      activeDay: "text-sm rounded-full bg-primary-50 w-12 h-12 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
      highlightedDay: "text-sm rounded-full bg-success-200 w-12 h-12 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
      day: "text-sm rounded-full w-12 h-12 mx-auto hover:bg-primary-50 disabled:opacity-50 disabled:cursor-not-allowed",
      today: "text-sm rounded-full w-12 h-12 mx-auto hover:bg-primary-50 disabled:opacity-50 disabled:cursor-not-allowed border border-primary-500",
      monthWrapper: "px-3 pt-2",
      selectedMonth: "text-sm rounded w-full h-12 mx-auto bg-primary-500 text-white",
      activeMonth: "text-sm rounded w-full h-12 mx-auto bg-primary-50",
      month: "text-sm rounded w-full h-12 mx-auto hover:bg-primary-50",
      yearWrapper: "px-3 pt-2",
      year: "text-sm rounded w-full h-12 mx-auto hover:bg-primary-50",
      selectedYear: "text-sm rounded w-full h-12 mx-auto bg-primary-500 text-white",
      activeYear: "text-sm rounded w-full h-12 mx-auto bg-primary-50",
      timepickerWrapper: "",
      timepickerTimeWrapper: "",
      timepickerTimeFieldsWrapper: "",
      timepickerOkButton: "",
      timepickerInput: "",
      timepickerTimeLabel: "",
      timepickerAmPmWrapper: "",
      timepickerAmPmWrapperChecked: "",
      timepickerAmPmWrapperDisabled: "",
      timepickerAmPmWrapperCheckedDisabled: "",
      timepickerAmPmButton: "",
      timepickerAmPmButtonChecked: "",
      timepickerAmPmCheckedPlaceholder: "",
      timepickerAmPmUncheckedPlaceholder: "",
    },
    variants: {
      standard: {
        wrapper: "flex flex-col",
        dropdownWrapper: "relative z-10",
        dropdown: "origin-top-left absolute rounded shadow bg-white dark:bg-gray-800 overflow-hidden mt-1",
        enterClass: "opacity-0 scale-95",
        enterActiveClass: "transition ease-out duration-100",
        enterToClass: "opacity-100 scale-100",
        leaveClass: "opacity-100 scale-100",
        leaveActiveClass: "transition ease-in duration-75",
        leaveToClass: "opacity-0 scale-95",
        inlineWrapper: "",
        inlineViews: "rounded bg-white dark:bg-gray-800 border mt-1 inline-flex",
        inputWrapper: "",
        input: "text-gray-800 dark:text-gray-50 placeholder-gray-400 border-gray-300 border",
        clearButton: "hover:bg-gray-100 dark:bg-gray-900 rounded transition duration-100 ease-in-out text-gray-600 dark:text-gray-300",
        clearButtonIcon: "",
        viewGroup: "",
        view: "",
        navigator: "pt-2 px-3",
        navigatorViewButton: "transition ease-in-out duration-100 inline-flex cursor-pointer rounded-full px-2 py-1 -ml-1 hover:bg-gray-100 dark:bg-gray-900",
        navigatorViewButtonIcon: "fill-current text-gray-400",
        navigatorViewButtonBackIcon: "fill-current text-gray-400",
        navigatorViewButtonMonth: "text-gray-700 dark:text-gray-100 font-semibold",
        navigatorViewButtonYear: "text-gray-500 dark:text-gray-200 ml-1",
        navigatorViewButtonYearRange: "text-gray-500 dark:text-gray-200 ml-1",
        navigatorLabel: "py-1",
        navigatorLabelMonth: "text-gray-700 dark:text-gray-100 font-semibold",
        navigatorLabelYear: "text-gray-500 dark:text-gray-200 ml-1",
        navigatorPrevButton: "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 dark:bg-gray-900 rounded-full p-1 ml-2 ml-auto disabled:opacity-50 disabled:cursor-not-allowed",
        navigatorNextButton: "transition ease-in-out duration-100 inline-flex cursor-pointer hover:bg-gray-100 dark:bg-gray-900 rounded-full p-1 -mr-1 disabled:opacity-50 disabled:cursor-not-allowed",
        navigatorPrevButtonIcon: "text-gray-400",
        navigatorNextButtonIcon: "text-gray-400",
        calendarWrapper: "px-3 pt-2",
        calendarHeaderWrapper: "",
        calendarHeaderWeekDay: "uppercase text-xs text-gray-500 dark:text-gray-200 w-8 h-8 flex items-center justify-center",
        calendarDaysWrapper: "",
        calendarDaysDayWrapper: "w-full h-8 flex shrink-0 items-center",
        otherMonthDay: "text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary-50 text-gray-400 disabled:opacity-50 disabled:cursor-not-allowed",
        emptyDay: "",
        inRangeFirstDay: "text-sm bg-primary-500 text-white w-full h-8 rounded-l-full",
        inRangeLastDay: "text-sm bg-primary-500 text-white w-full h-8 rounded-r-full",
        inRangeDay: "text-sm bg-primary-200 w-full h-8 disabled:opacity-50 disabled:cursor-not-allowed",
        selectedDay: "text-sm rounded-full w-8 h-8 mx-auto bg-primary-500 text-white disabled:opacity-50 disabled:cursor-not-allowed",
        activeDay: "text-sm rounded-full bg-primary-50 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
        highlightedDay: "text-sm rounded-full bg-primary-200 w-8 h-8 mx-auto disabled:opacity-50 disabled:cursor-not-allowed",
        day: "text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary-50 disabled:opacity-50 disabled:cursor-not-allowed",
        today: "text-sm rounded-full w-8 h-8 mx-auto hover:bg-primary-50 disabled:opacity-50 disabled:cursor-not-allowed border border-primary-500",
        monthWrapper: "px-3 pt-2",
        selectedMonth: "text-sm rounded w-full h-12 mx-auto bg-primary-500 text-white",
        activeMonth: "text-sm rounded w-full h-12 mx-auto bg-primary-50",
        month: "text-sm rounded w-full h-12 mx-auto hover:bg-primary-50",
        yearWrapper: "px-3 pt-2",
        year: "text-sm rounded w-full h-12 mx-auto hover:bg-primary-50",
        selectedYear: "text-sm rounded w-full h-12 mx-auto bg-primary-500 text-white",
        activeYear: "text-sm rounded w-full h-12 mx-auto bg-primary-50",
      },
      danger: {
        input: "border-danger-300 bg-danger-50 placeholder-danger-200 text-danger-900",
        clearButton: "hover:bg-danger-200 text-danger-500",
      },
      input: {
        input: "border-gray-300 focus:border-primary-500",
      },
    },
  },
};
