import {RootState} from "@/js/store/types";
import {ActionContext, ActionTree, Module, MutationTree} from "vuex";

export type ReportSetupState = {
  setup: Array<App.DataObjects.Widgets.TableBasedReportWidgetInterface | App.DataObjects.Widgets.TrendWidgetInterface>;
};

const state: ReportSetupState = {
  setup: [],
};

export type ReportSetupMutations<S = ReportSetupState> = {
  setSetup(state: S, setup: Array<App.DataObjects.Widgets.TableBasedReportWidgetInterface | App.DataObjects.Widgets.TrendWidgetInterface>): void;
};

const mutations: MutationTree<ReportSetupState> & ReportSetupMutations = {
  setSetup(state: ReportSetupState, setup: Array<App.DataObjects.Widgets.TableBasedReportWidgetInterface | App.DataObjects.Widgets.TrendWidgetInterface>) {
    state.setup = [];
    setup.forEach((item) => {
      state.setup.push(item);
    });
  },
};

export type ReportSetupActions = {
  updateSetup(context: ActionContext<ReportSetupState, RootState>, payload: Array<App.DataObjects.Widgets.TableBasedReportWidgetInterface | App.DataObjects.Widgets.TrendWidgetInterface>): void;
};

const actions: ActionTree<ReportSetupState, RootState> & ReportSetupActions = {
  updateSetup({commit}: ActionContext<ReportSetupState, RootState>, payload: Array<App.DataObjects.Widgets.TableBasedReportWidgetInterface | App.DataObjects.Widgets.TrendWidgetInterface>) {
    commit("setSetup", payload);
  },
};

const reportSetup: Module<ReportSetupState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default reportSetup;
