import TCheckbox from "vue-tailwind/dist/t-checkbox";

export default {
  component: TCheckbox,
  props: {
    fixedClasses:
      "transition duration-100 ease-in-out rounded shadow-sm focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed",
    classes: "text-primary-500 border-gray-300",
    variants: {
      error: "text-danger-500 border-danger-300",
      success: "text-success-500 border-success-300",
    },
  },
};
