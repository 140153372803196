import Alert from "@/js/vue-tailwind/settings/alert";
import Button from "@/js/vue-tailwind/settings/button";
import Card from "@/js/vue-tailwind/settings/card";
import Checkbox from "@/js/vue-tailwind/settings/checkbox";
import CheckboxGroup from "@/js/vue-tailwind/settings/checkboxgroup";
import DatePicker from "@/js/vue-tailwind/settings/datepicker";
import Dialog from "@/js/vue-tailwind/settings/dialog";
import Dropdown from "@/js/vue-tailwind/settings/dropdown";
import Input from "@/js/vue-tailwind/settings/input";
import InputGroup from "@/js/vue-tailwind/settings/inputgroup";
import Modal from "@/js/vue-tailwind/settings/modal";
import Pagination from "@/js/vue-tailwind/settings/pagination";
import Radio from "@/js/vue-tailwind/settings/radio";
import RadioGroup from "@/js/vue-tailwind/settings/radiogroup";
import RichSelect from "@/js/vue-tailwind/settings/richselect";
import Select from "@/js/vue-tailwind/settings/select";
import Table from "@/js/vue-tailwind/settings/table";
import Tag from "@/js/vue-tailwind/settings/tag";
import TextArea from "@/js/vue-tailwind/settings/textarea";
import Toggle from "@/js/vue-tailwind/settings/toggle";

export default {
  "t-alert": Alert,
  "t-button": Button,
  "t-card": Card,
  "t-checkbox": Checkbox,
  "t-checkbox-group": CheckboxGroup,
  "t-datepicker": DatePicker,
  "t-dialog": Dialog,
  "t-dropdown": Dropdown,
  "t-input": Input,
  "t-input-group": InputGroup,
  "t-modal": Modal,
  "t-pagination": Pagination,
  "t-radio": Radio,
  "t-radio-group": RadioGroup,
  "t-rich-select": RichSelect,
  "t-select": Select,
  "t-table": Table,
  "t-tag": Tag,
  "t-textarea": TextArea,
  "t-toggle": Toggle,
};
