const facebookTracker = (event: string, data: any = null) => {
  const eventsObject: {[key: string]: string} = {
    bulkAgentAdded: "Agent Added",
    reportPDFDownloaded: "Download PDF",
  };

  if (typeof window.fbq === "undefined") {
    return;
  }

  const eventName = eventsObject[event] ? eventsObject[event] : event;

  if (data) {
    return window.fbq("trackCustom", eventName, data);
  }

  return window.fbq("trackCustom", eventName);
};

const googleAnalyticsTracker = (event: string) => {
  if (typeof dataLayer === "undefined") {
    return;
  }
  dataLayer.push({event: event});
};

const mixpanelTracker = (event: string, data: any = null) => {
  if (typeof mixpanel === "undefined") {
    return;
  }
  if (data) {
    return mixpanel.track(event, data);
  }
  return mixpanel.track(event, undefined);
};

const juneTracker = (event: string, data: any = null) => {
  if (typeof window.analytics === "undefined") {
    return;
  }
  if (data) {
    return window.analytics.track(event, data);
  }
  return window.analytics.track(event);
};

const trackEvent = (event: string, data: any = null) => {
  googleAnalyticsTracker(event);
  facebookTracker(event, data);
  mixpanelTracker(event, data);
  juneTracker(event, data);
};

export {trackEvent};
