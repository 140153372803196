import TRichSelect from "vue-tailwind/dist/t-rich-select";

export default {
  component: TRichSelect,
  props: {
    fixedClasses: {
      wrapper: "relative",
      buttonWrapper: "inline-block relative w-full",
      selectButton:
        "w-full flex text-left justify-between items-center px-3 py-2 text-gray-800 dark:text-gray-50 transition duration-100 ease-in-out border rounded shadow-sm focus:border-primary-500 focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      selectButtonLabel: "block truncate",
      selectButtonTagWrapper: "flex flex-wrap overflow-hidden",
      selectButtonTag: "inline-flex items-baseline px-2 rounded-full text-sm leading-4 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-50 py-1 gap-1",
      selectButtonTagText: "",
      selectButtonTagDeleteButton: "text-danger-500 rounded-full border border-danger-500",
      selectButtonTagDeleteButtonIcon: "w-3 h-3",
      selectButtonPlaceholder: "block truncate",
      selectButtonIcon: "fill-current shrink-0 ml-1 h-4 w-4",
      selectButtonClearButton: "rounded flex shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6 transition duration-100 ease-in-out",
      selectButtonClearIcon: "fill-current h-3 w-3",
      dropdown: "absolute w-full z-10 -mt-1 absolute border-b border-l border-r rounded-b shadow-sm z-10",
      dropdownFeedback: "",
      loadingMoreResults: "",
      optionsList: "overflow-auto",
      searchWrapper: "inline-block w-full",
      searchBox: "inline-block w-full",
      optgroup: "",
      option: "cursor-pointer",
      disabledOption: "opacity-50 cursor-not-allowed",
      highlightedOption: "cursor-pointer",
      selectedOption: "cursor-pointer",
      selectedHighlightedOption: "cursor-pointer",
      optionContent: "",
      optionLabel: "truncate block",
      selectedIcon: "fill-current h-4 w-4",
      enterClass: "",
      enterActiveClass: "",
      enterToClass: "",
      leaveClass: "",
      leaveActiveClass: "",
      leaveToClass: "",
    },
    classes: {
      wrapper: "",
      buttonWrapper: "",
      selectButton: "bg-white dark:bg-gray-800 border-gray-300",
      selectButtonLabel: "",
      selectButtonTagWrapper: "",
      selectButtonTag: "",
      selectButtonTagText: "",
      selectButtonTagDeleteButton: "",
      selectButtonTagDeleteButtonIcon: "",
      selectButtonPlaceholder: "text-gray-400",
      selectButtonIcon: "text-gray-600 dark:text-gray-300",
      selectButtonClearButton: "hover:bg-navy-50 text-gray-600 dark:text-gray-300",
      selectButtonClearIcon: "",
      dropdown: "bg-white dark:bg-gray-800 border-gray-300",
      dropdownFeedback: "pb-2 px-3 text-gray-400 text-sm",
      loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
      optionsList: "",
      searchWrapper: "p-2 placeholder-gray-400",
      searchBox: "px-3 py-2 bg-gray-50 dark:bg-gray-800 text-sm rounded border focus:outline-none focus:shadow-outline border-gray-300",
      optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
      option: "",
      disabledOption: "",
      highlightedOption: "bg-navy-50",
      selectedOption: "font-semibold bg-gray-100 dark:bg-gray-900 bg-primary-500 font-semibold text-white",
      selectedHighlightedOption: "font-semibold bg-gray-100 dark:bg-gray-900 bg-navy-600 font-semibold text-white",
      optionContent: "flex justify-between items-center px-3 py-2",
      optionLabel: "",
      selectedIcon: "",
      enterClass: "opacity-0",
      enterActiveClass: "transition ease-out duration-100",
      enterToClass: "opacity-100",
      leaveClass: "opacity-100",
      leaveActiveClass: "transition ease-in duration-75",
      leaveToClass: "opacity-0",
    },
    variants: {
      danger: {
        selectButton: "border-danger-300 bg-danger-50 text-danger-900",
        selectButtonPlaceholder: "text-danger-200",
        selectButtonIcon: "text-danger-500",
        selectButtonClearButton: "hover:bg-danger-200 text-danger-500",
        dropdown: "bg-danger-50 border-danger-300",
      },
      success: {
        selectButton: "border-success-300 bg-success-50 text-navy-900 dark:text-navy-200",
        selectButtonIcon: "text-success-500",
        selectButtonClearButton: "hover:bg-success-200 text-navy-800",
        dropdown: "bg-success-50 border-success-300",
      },
    },
  },
};
