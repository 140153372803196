import {OptimizerPreview, SectionsExpanded} from "@/js/components/optimizer/optimizer-widget-types";
import {defaultCurrentThread, defaultOptimizerSettings} from "@/js/components/optimizer/optimizerHelpers";
import {defaultPaginator} from "@/js/misc/defaultObjects";
import {RootState} from "@/js/store/types";
import Vue from "vue";
import {GetterTree, Module, MutationTree} from "vuex";

export type OptimizerSettings = App.DataObjects.OptimizerData.OptimizerSettingsOnlyData & {
  customize_for: Extract<App.Enums.ModelType, "Company" | "Mailbox" | "Team">;
  customize_for_model: App.DataObjects.ElasticsearchEntityObject | null;
  apply_to_team: boolean;
};

export type OptimizerSetupState = {
  isLoading: boolean;
  possibleWidgets: App.DataObjects.OptimizerData.OptimizerWidget[];
  settings: OptimizerSettings;
  sectionsExpanded: SectionsExpanded;
  preview: OptimizerPreview;
  comparison_list: Record<string, string>;
};

const state: OptimizerSetupState = {
  isLoading: false,
  possibleWidgets: [],
  settings: {
    customize_for: "Company",
    customize_for_model: null,
    apply_to_team: false,
    ...defaultOptimizerSettings(),
  },
  sectionsExpanded: {
    conversationActions: true,
    performanceStats: true,
    importantEmails: true,
  },
  preview: {
    currentThread: defaultCurrentThread(),
    days: 7,
    comparisonList: {},
    comparisonItemSelected: undefined,
    priorityEmails: defaultPaginator(),
    assignedEmails: defaultPaginator(),
    showHidden: false,
    importantEmailsCurrentTab: "priority",
    statDetails: {
      show: false,
      showHidden: false,
      stat: null as App.Enums.OptimizerWidgetId | null,
      items: [] as Array<App.DataObjects.OptimizerData.OptimizerMessageData>,
      itemType: "email message",
      total: 0,
      perPage: 10,
      page: 1,
      title: "",
      from: 0,
    },
  },
  comparison_list: {},
};

export type OptimizerMutations<S = OptimizerSetupState> = {
  setSettings(state: S, settings: OptimizerSettings): void;
  setSectionsExpanded(state: S, sectionsExpanded: SectionsExpanded): void;
  setPreview(state: S, preview: OptimizerPreview): void;
  setIsLoading(state: S, isLoading: boolean): void;
  setPossibleWidgets(state: S, possibleWidgets: App.DataObjects.OptimizerData.OptimizerWidget[]): void;
};

const mutations: MutationTree<OptimizerSetupState> & OptimizerMutations = {
  setSettings(state, settings: OptimizerSettings) {
    state.settings.customize_for = settings.customize_for;
    state.settings.customize_for_model = settings.customize_for_model;
    state.settings.conversation_actions.enabled = settings.conversation_actions.enabled;
    state.settings.primary_widget = [];
    settings.primary_widget.forEach((widget) => {
      state.settings.primary_widget.push(widget);
    });
    state.settings.secondary_widgets = [];
    settings.secondary_widgets.forEach((widget) => {
      state.settings.secondary_widgets.push(widget);
    });
    state.settings.email_controls.view = settings.email_controls.view;
    state.settings.email_controls.snooze = settings.email_controls.snooze;
    state.settings.email_controls.assign = settings.email_controls.assign;
    state.settings.email_controls.close = settings.email_controls.close;
    state.settings.email_controls.hide = settings.email_controls.hide;
    state.settings.important_emails.enabled = settings.important_emails.enabled;
  },

  setSectionsExpanded(state, sectionsExpanded: SectionsExpanded) {
    state.sectionsExpanded.conversationActions = sectionsExpanded.conversationActions;
    state.sectionsExpanded.performanceStats = sectionsExpanded.performanceStats;
    state.sectionsExpanded.importantEmails = sectionsExpanded.importantEmails;
  },

  setPreview(state, preview: OptimizerPreview) {
    state.preview.currentThread.id = preview.currentThread.id;
    state.preview.currentThread.type = preview.currentThread.type;
    state.preview.currentThread.status = preview.currentThread.status;
    state.preview.currentThread.firstReplyTime = preview.currentThread.firstReplyTime;
    state.preview.currentThread.messagesCount = preview.currentThread.messagesCount;
    state.preview.currentThread.timeToClose = preview.currentThread.timeToClose;
    state.preview.currentThread.isBlocked = preview.currentThread.isBlocked;
    state.preview.currentThread.isSnoozed = preview.currentThread.isSnoozed;
    state.preview.currentThread.isHidden = preview.currentThread.isHidden;
    state.preview.currentThread.isClosed = preview.currentThread.isClosed;
    state.preview.currentThread.messageId = preview.currentThread.messageId;
    state.preview.currentThread.isAssignedToMe = preview.currentThread.isAssignedToMe;
    state.preview.currentThread.isAssignedToSomeoneElse = preview.currentThread.isAssignedToSomeoneElse;
    state.preview.currentThread.notes = [];
    preview.currentThread.notes.forEach((note) => {
      state.preview.currentThread.notes.push(note);
    });
    state.preview.currentThread.isNull = preview.currentThread.isNull;

    state.preview.priorityEmails = defaultPaginator();
    state.preview.priorityEmails.current_page = preview.priorityEmails.current_page;
    preview.priorityEmails.data.forEach((email) => {
      state.preview.priorityEmails.data.push(email);
    });
    state.preview.priorityEmails.total = preview.priorityEmails.total;

    state.preview.assignedEmails = defaultPaginator();
    state.preview.assignedEmails.current_page = preview.assignedEmails.current_page;
    preview.assignedEmails.data.forEach((email) => {
      state.preview.assignedEmails.data.push(email);
    });
    state.preview.assignedEmails.total = preview.assignedEmails.total;

    state.preview.comparisonList = {};
    for (const [key, value] of Object.entries(preview.comparisonList)) {
      Vue.set(state.preview.comparisonList, key, value);
    }
    state.preview.comparisonItemSelected = preview.comparisonItemSelected;

    state.preview.days = preview.days;

    state.preview.importantEmailsCurrentTab = preview.importantEmailsCurrentTab;

    state.preview.statDetails.show = preview.statDetails.show;
    state.preview.statDetails.showHidden = preview.statDetails.showHidden;
    state.preview.statDetails.stat = preview.statDetails.stat;
    state.preview.statDetails.items = [];
    preview.statDetails.items.forEach((item) => {
      state.preview.statDetails.items.push(item);
    });
    state.preview.statDetails.itemType = preview.statDetails.itemType;
    state.preview.statDetails.total = preview.statDetails.total;
    state.preview.statDetails.perPage = preview.statDetails.perPage;
    state.preview.statDetails.page = preview.statDetails.page;
    state.preview.statDetails.title = preview.statDetails.title;
    state.preview.statDetails.from = preview.statDetails.from;
  },

  setIsLoading(state, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  setPossibleWidgets(state, possibleWidgets: App.DataObjects.OptimizerData.OptimizerWidget[]) {
    state.possibleWidgets = [];
    possibleWidgets.forEach((widget) => {
      state.possibleWidgets.push(widget);
    });
  },
};

export type OptimizerGetters = {
  shouldShowTeamSelection: (state: OptimizerSetupState) => boolean;
};

const getters: GetterTree<OptimizerSetupState, RootState> & OptimizerGetters = {
  shouldShowTeamSelection: (state: OptimizerSetupState) => {
    if (state.possibleWidgets.length) {
      return true;
    }
    return (
      Object.keys(state.preview.comparisonList).length > 0 &&
      (state.settings.primary_widget.some((widget: App.DataObjects.OptimizerData.OptimizerWidget) => widget.config.showTeamGroupMailbox) ||
        state.settings.secondary_widgets.some((widget: App.DataObjects.OptimizerData.OptimizerWidget) => widget.config.showTeamGroupMailbox))
    );
  },
};

const optimizerSetup: Module<OptimizerSetupState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations,
};

export default optimizerSetup;
