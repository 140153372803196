import {apiReportsProductivityIndex} from "@/js/http/api";
import {getErrorMessageFromValidationError} from "@/js/http/http";
import {allLeads, anybody, defaultHourOfDay, defaultTableData, myCompany} from "@/js/misc/defaultObjects";
import {HourOfDay, RootGetters, RootState, SearchParams, TableData} from "@/js/store/types";
import {AxiosError} from "axios";
import {get} from "lodash-es";
import {ActionContext, ActionTree, GetterTree, Module, MutationTree} from "vuex";

export type ProductivityMessages = {
  received: {
    hourOfDay: HourOfDay;
  };
  sent: {
    hourOfDay: HourOfDay;
  };
};

export type ProductivityStats = {
  messages: ProductivityMessages;
};

let cancelController: AbortController | undefined = undefined;

export type ProductivityState = {
  productivityLoading: boolean;
  total: number;
  emailVolumes: TableData;
  conversations: TableData;
  averageReplyTimes: TableData;
  responsiveness: TableData;
  activity: TableData;
  productivityStats: ProductivityStats;
  noData: boolean;
  initialDataSet: boolean;
};

const state: ProductivityState = {
  productivityLoading: true,
  total: 0,
  emailVolumes: defaultTableData(),
  conversations: defaultTableData(),
  averageReplyTimes: defaultTableData(),
  responsiveness: defaultTableData(),
  activity: defaultTableData(),
  productivityStats: {
    messages: {
      received: {
        hourOfDay: defaultHourOfDay(),
      },
      sent: {
        hourOfDay: defaultHourOfDay(),
      },
    },
  },
  noData: false,
  initialDataSet: false,
};

export type ProductivityMutations<S = ProductivityState> = {
  setInitialDataSet(state: S, initialDataSet: boolean): void;
  setLoading(state: S, status: boolean): void;
  setTotal(state: S, total: number): void;
  setTableData(state: S, payload: {tableName: "emailVolumes" | "conversations" | "averageReplyTimes" | "responsiveness" | "activity"; tableData: TableData}): void;
  toggleVisibility(state: S, payload: {tableName: "emailVolumes" | "conversations" | "averageReplyTimes" | "responsiveness" | "activity"; index: number}): void;
  setStats(state: S, stats: App.DataObjects.ReportResponses.Stats.Stats): void;
  setNoData(state: S, value: boolean): void;
};

const mutations: MutationTree<ProductivityState> & ProductivityMutations = {
  setInitialDataSet(state: ProductivityState, initialDataSet: boolean) {
    state.initialDataSet = initialDataSet;
  },

  setLoading(state: ProductivityState, status: boolean) {
    state.productivityLoading = status;
    state.emailVolumes.loading = status;
    state.conversations.loading = status;
    state.averageReplyTimes.loading = status;
    state.responsiveness.loading = status;
    state.activity.loading = status;
  },

  setTotal(state: ProductivityState, total: number) {
    state.total = total;
  },

  setTableData(
    state: ProductivityState,
    {
      tableName,
      tableData,
    }: {
      tableName: "emailVolumes" | "conversations" | "averageReplyTimes" | "responsiveness" | "activity";
      tableData: TableData;
    },
  ) {
    state[tableName] = defaultTableData();
    tableData.columns.forEach((col) => state[tableName].columns.push(col));
    tableData.data.forEach((row) => state[tableName].data.push(row));
    state[tableName].default_sort = tableData.default_sort;
    state[tableName].default_sort_direction = tableData.default_sort_direction;
    state[tableName].per_page = tableData.per_page;
    state[tableName].total = tableData.total;
    state[tableName].page = tableData.page;
    state[tableName].loading = false;
  },

  toggleVisibility(
    state: ProductivityState,
    {
      tableName,
      index,
    }: {
      tableName: "emailVolumes" | "conversations" | "averageReplyTimes" | "responsiveness" | "activity";
      index: number;
    },
  ) {
    state[tableName].columns[index].visible = !state[tableName].columns[index].visible;
  },

  setStats(state: ProductivityState, stats: App.DataObjects.ReportResponses.Stats.Stats) {
    for (let i = 0; i < 24; i++) {
      const hour = i < 10 ? "0" + i + ":00" : i + ":00";
      state.productivityStats.messages.received.hourOfDay[hour] = get(stats, `messages.received.hourOfDay[${hour}]`, 0);
      state.productivityStats.messages.sent.hourOfDay[hour] = get(stats, `messages.sent.hourOfDay[${hour}]`, 0);
    }
  },

  setNoData(state: ProductivityState, value: boolean) {
    state.noData = value;
  },
};

export type ProductivityGetters<S = ProductivityState> = {
  requestParams: (state: S, getters: ProductivityGetters, rootState: RootState, rootGetters: RootGetters) => SearchParams;
};

const getters: GetterTree<ProductivityState, RootState> & ProductivityGetters = {
  requestParams: (state: ProductivityState, getters: ProductivityGetters, rootState: RootState, rootGetters: RootGetters) => {
    const params: SearchParams = {...rootGetters["threadFilters/searchParams"]};
    params.per_page = rootState.threadFilters.productivity.perPage;
    params.page = rootState.threadFilters.productivity.page;

    params.sort_by_email_volumes = rootState.threadFilters.productivity.email_volumes.sortBy;
    params.direction_email_volumes = rootState.threadFilters.productivity.email_volumes.direction;
    params.sort_by_conversations = rootState.threadFilters.productivity.conversations.sortBy;
    params.direction_conversations = rootState.threadFilters.productivity.conversations.direction;
    params.sort_by_average_reply_times = rootState.threadFilters.productivity.average_reply_times.sortBy;
    params.direction_average_reply_times = rootState.threadFilters.productivity.average_reply_times.direction;
    params.sort_by_responsiveness = rootState.threadFilters.productivity.responsiveness.sortBy;
    params.direction_responsiveness = rootState.threadFilters.productivity.responsiveness.direction;
    params.sort_by_activity = rootState.threadFilters.productivity.activity.sortBy;
    params.direction_activity = rootState.threadFilters.productivity.activity.direction;

    return params;
  },
};

export type ProductivityActions<S = ProductivityState, R = RootState> = {
  loadAsyncData(context: ActionContext<S, R>): void;
  setData(context: ActionContext<S, R>, data: any): void;
  toggleVisibilityEmailVolumes(context: ActionContext<S, R>, index: number): void;
  toggleVisibilityActivity(context: ActionContext<S, R>, index: number): void;
  toggleVisibilityConversations(context: ActionContext<S, R>, index: number): void;
  toggleVisibilityAverageReplyTimes(context: ActionContext<S, R>, index: number): void;
  toggleVisibilityResponsiveness(context: ActionContext<S, R>, index: number): void;
};

const actions: ActionTree<ProductivityState, RootState> & ProductivityActions = {
  loadAsyncData({dispatch, commit, getters}: ActionContext<ProductivityState, RootState>) {
    commit("setLoading", true);
    const params = {...getters.requestParams};

    if (cancelController) {
      cancelController.abort();
    }

    cancelController = new AbortController();

    apiReportsProductivityIndex(params, {
      signal: cancelController.signal,
    })
      .then((response) => {
        dispatch("setData", response.data);
      })
      .catch((error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) => {
        if (error.code === "ERR_CANCELED") {
          return;
        }
        commit("setLoading", false);

        dispatch(
          "user/recordErrors",
          {
            data: getErrorMessageFromValidationError(error, "Something went wrong loading your data. Try making the date range smaller. If the problem persists, please contact us."),
            type: "toast",
          },
          {root: true},
        );
      })
      .finally(() => (cancelController = undefined));
  },

  setData({commit, rootState}: ActionContext<ProductivityState, RootState>, data: any) {
    commit(
      "threadFilters/setProductivity",
      {
        page: parseInt(data.page),
      },
      {root: true},
    );

    commit("setTableData", {tableName: "emailVolumes", tableData: data.email_volumes});

    commit("setTableData", {tableName: "conversations", tableData: data.conversations});

    commit("setTableData", {tableName: "averageReplyTimes", tableData: data.average_reply_times});

    commit("setTableData", {tableName: "responsiveness", tableData: data.responsiveness});

    commit("setTableData", {tableName: "activity", tableData: data.activity});

    commit("setStats", data.stats);
    if (data.email_volumes.data.length === 0) {
      commit("setNoData", true);
    } else {
      commit("setNoData", false);
    }

    commit("setTotal", data.total);

    commit("threadFilters/setModelSelectedFull", get(data, "args.model", myCompany()), {root: true});

    const productType = rootState.threadFilters.productType;

    commit("threadFilters/setModelSelectedComFull", get(data, "args.modelCom", productType === "success" ? anybody() : allLeads()), {root: true});

    commit("setLoading", false);

    commit("setInitialDataSet", true);
  },

  toggleVisibilityEmailVolumes({commit}: ActionContext<ProductivityState, RootState>, index: number) {
    commit("toggleVisibility", {tableName: "emailVolumes", index});
  },

  toggleVisibilityActivity({commit}: ActionContext<ProductivityState, RootState>, index: number) {
    commit("toggleVisibility", {tableName: "activity", index});
  },

  toggleVisibilityConversations({commit}: ActionContext<ProductivityState, RootState>, index: number) {
    commit("toggleVisibility", {tableName: "conversations", index});
  },

  toggleVisibilityAverageReplyTimes({commit}: ActionContext<ProductivityState, RootState>, index: number) {
    commit("toggleVisibility", {tableName: "averageReplyTimes", index});
  },

  toggleVisibilityResponsiveness({commit}: ActionContext<ProductivityState, RootState>, index: number) {
    commit("toggleVisibility", {tableName: "responsiveness", index});
  },
};

const productivity: Module<ProductivityState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default productivity;
