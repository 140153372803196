import TTag from "vue-tailwind/dist/t-tag";

export default {
  component: TTag,
  props: {
    fixedClasses: "",
    classes: "",
    variants: {
      title: "text-lg sm:text-2xl leading-8 font-extrabold text-gray-900 tracking-tight",
      subtitle: "text-lg leading-6 font-medium text-gray-900",
      error: "text-danger-500",
      badge: "inline-flex items-baseline px-2 rounded-full text-sm leading-4 bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-50 py-1 gap-1",
      avatar: "inline-flex items-center justify-center h-10 w-10 rounded-full bg-gray-500 overflow-hidden leading-none text-center",
    },
  },
};
