import TInput from "vue-tailwind/dist/t-input";

export default {
  component: TInput,
  props: {
    fixedClasses:
      "block px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed w-full placeholder-gray-500",
    classes: "text-gray-800 dark:text-gray-50 bg-white dark:bg-gray-800 border-gray-300 focus:border-primary-500 z-1 disabled:bg-gray-200 disabled:text-gray-500 dark:text-gray-200",
    variants: {
      danger: "border-danger-300 bg-danger-50 text-danger-900",
      success: "border-success-300 bg-success-50 text-success-900",
      narrow: "text-gray-800 dark:text-gray-50 bg-white dark:bg-gray-800 border-gray-300 focus:border-primary-500 z-1 w-20",
    },
  },
};
