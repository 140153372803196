import {launchToast} from "@/js/store/common_functions/functions";
import {ErrorPayload, RecordErrorPayload, RecordStatusPayload, RootState} from "@/js/store/types";
import {forEach, has} from "lodash-es";
import {ActionContext, ActionTree, Module, MutationTree} from "vuex";

export type UserState = {
  user: App.Models.User | null;
  company: App.Models.Company | null;
  status: string | null;
  error: string | null;
  billingError: string | null;
  errors: Array<{key: string; value: string}>;
  productType: App.Enums.ProductType | null;
};

const state: UserState = {
  user: null,
  company: null,
  status: null,
  error: null,
  billingError: null,
  errors: [],
  productType: null,
};

export type UserMutations<S = UserState> = {
  setUser(state: S, user: App.Models.User | null): void;
  setCompany(state: S, company: App.Models.Company | null): void;
  setStatus(state: S, status: string | null): void;
  clearStatus(state: S): void;
  setError(state: S, error: string | null): void;
  pushToErrors(state: S, error: ErrorPayload): void;
  clearError(state: S): void;
  clearErrors(state: S): void;
  setBillingError(state: S, billingError: string | null): void;
  setProductType(state: S, productType: App.Enums.ProductType | null): void;
};

const mutations: MutationTree<UserState> & UserMutations = {
  setUser(state, user) {
    state.user = user;
  },
  setCompany(state, company) {
    state.company = company;
  },
  setStatus(state, status) {
    if (status != null) {
      state.status = status;
    }
  },
  clearStatus(state) {
    state.status = null;
  },
  setError(state, error) {
    state.error = error;
  },
  pushToErrors(state, error) {
    state.errors.push({key: error.key, value: error.value});
  },
  clearError(state) {
    state.error = null;
  },
  clearErrors(state) {
    state.errors = [];
  },
  setBillingError(state, billingError) {
    state.billingError = billingError;
  },
  setProductType(state, productType) {
    state.productType = productType;
  },
};

export type UserActions = {
  recordErrors(context: ActionContext<UserState, RootState>, payload: RecordErrorPayload): void;
  recordStatus(context: ActionContext<UserState, RootState>, payload: RecordStatusPayload): void;
};

const actions: ActionTree<UserState, RootState> & UserActions = {
  recordErrors({commit}, payload) {
    commit("clearError");
    commit("clearErrors");
    commit("clearStatus");
    if (payload != null) {
      const type = has(payload, "type") ? payload.type : "full";
      if (has(payload, "data")) {
        let message;
        if (has(payload.data, "anError")) {
          message = payload.data.anError;
        } else if (has(payload.data, "message")) {
          message = payload.data.message;
        } else if (typeof payload.data === "string") {
          message = payload.data;
        } else {
          message = "An Error Occurred";
        }

        if (has(payload.data, "errors")) {
          forEach(payload.data.errors, (value, key) => {
            commit("pushToErrors", {key, value});
          });
        }

        if (type === "full") {
          commit("setError", message);
        } else {
          launchToast(message, "danger");
        }
      }
    }
  },
  recordStatus({commit}, payload) {
    commit("clearError");
    commit("clearErrors");
    commit("clearStatus");
    if (payload != null) {
      const type = has(payload, "type") ? payload.type : "toast";
      if (has(payload, "data")) {
        let message;
        if (has(payload.data, "status")) {
          message = payload.data.status;
        } else if (typeof payload.data === "string") {
          message = payload.data;
        } else {
          message = "Success";
        }
        if (type === "full") {
          commit("setStatus", message);
        } else {
          launchToast(message, "success");
        }
      }
    }
  },
};

const user: Module<UserState, RootState> = {
  namespaced: true,
  state,
  actions,
  mutations,
};

export default user;
