import TTextarea from "vue-tailwind/dist/t-textarea";

export default {
  component: TTextarea,
  props: {
    fixedClasses: "block w-full px-3 py-2 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-primary-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
    classes: "text-gray-800 dark:text-gray-50 placeholder-gray-400 bg-white dark:bg-gray-800 border-gray-300 focus:border-primary-500 ",
    variants: {
      danger: "border-danger-300 bg-danger-50 placeholder-danger-200 text-danger-900",
      success: "border-success-300 bg-success-50 placeholder-gray-400 text-success-900",
    },
  },
};
