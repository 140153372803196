import {launchToast} from "@/js/store/common_functions/functions";
import axios, {AxiosError, AxiosInstance, AxiosResponse} from "axios";
import {get, has} from "lodash-es";
import Vue from "vue";

class Errors {
  errors: Record<string, any[]>;

  constructor() {
    this.errors = {};
  }

  get(field: string): string | null {
    const error = get(this.errors, field, null);
    if (Array.isArray(error) && error.length > 0) {
      return error[0];
    }
    return null;
  }

  getFromArray(field: string): string | null {
    const keys = Object.keys(this.errors);
    for (const key of keys) {
      const beginning = key.split(".")[0];
      if (field === beginning) {
        return this.errors[key][0];
      }
    }
    return null;
  }

  getKeysForArray(field: string): number[] {
    const keys = Object.keys(this.errors);
    const resultKeys: number[] = [];
    for (const key of keys) {
      const beginning = key.split(".")[0];
      if (field === beginning) {
        resultKeys.push(parseInt(key.split(".")[1]));
      }
    }
    return resultKeys;
  }

  record(errors: Record<string, any>): void {
    Vue.set(this, "errors", errors);
  }

  clear(field: string): void {
    delete this.errors[field];
  }

  clearAll(): void {
    this.errors = {};
  }

  clearArray(field: string): void {
    const keys = Object.keys(this.errors);
    for (const key of keys) {
      const beginning = key.split(".")[0];
      if (field === beginning) {
        delete this.errors[key];
      }
    }
  }

  has(field: string): boolean {
    return has(this.errors, field);
  }

  hasFromArray(field: string): boolean {
    const keys = Object.keys(this.errors);
    for (const key of keys) {
      const beginning = key.split(".")[0];
      if (field === beginning) {
        return true;
      }
    }
    return false;
  }
}

const ownErrors = new Errors();

const defaultAxios: AxiosInstance = axios.create();

defaultAxios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// Laravel csrf token
const token = document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement | null;

if (token && token.content) {
  defaultAxios.defaults.headers.common["X-CSRF-TOKEN"] = token.content;
}

defaultAxios.interceptors.response.use(
  function (response: AxiosResponse): AxiosResponse {
    if (response.headers["tracking-events"]) {
      fireTrackingEvents(response.headers["tracking-events"]);
    }
    ownErrors.clearAll();
    return response;
  },
  function (error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>) {
    const status = get(error, "response.status", null);
    if (status === 401 || status === 419) {
      launchToast("Your session expired due to inactivity, please refresh the page to continue", "danger", 8000, function () {
        window.location.reload();
      });
      setTimeout(function () {
        window.location.reload();
      }, 8000);
    }
    if (has(error, "response.data.errors")) {
      /* @ts-ignore */
      ownErrors.record(error.response.data.errors);
    }
    if (has(error, "response.data")) {
      console.warn({"Error response": error.response?.data});
    }

    throw error;
  },
);

function fireTrackingEvents(trackingEvents: string) {
  const trackingEventObjects: any[] = JSON.parse(trackingEvents);
  trackingEventObjects.forEach((trackingEvent) => {
    if (trackingEvent["fb-event"]) {
      handleFacebookEvents(trackingEvent["fb-event"]);
    } else if (trackingEvent["sendGoogleAnalyticsEvent"]) {
      handleGoogleEvents(trackingEvent["sendGoogleAnalyticsEvent"]);
    } else if (trackingEvent["june-event"]) {
      handleJuneEvent(trackingEvent["june-event"]);
    }
  });
}

function handleFacebookEvents(eventObject: any) {
  if (typeof window.fbq === "undefined") {
    return;
  }
  if (eventObject["data"]) {
    window.fbq("trackCustom", eventObject["name"], eventObject["data"]);
  } else {
    window.fbq("trackCustom", eventObject["name"]);
  }
}

function handleGoogleEvents(eventObject: any) {
  if (typeof dataLayer === "undefined") {
    return;
  }
  dataLayer.push({event: eventObject["name"]});
}

function handleJuneEvent(eventObject: any) {
  if (typeof window.analytics === "undefined") {
    return;
  }
  if (eventObject["data"]) {
    window.analytics.track(eventObject["name"], eventObject["data"]);
  } else {
    window.analytics.track(eventObject["name"]);
  }
}

export const isLaravelValidationErrors = (obj: any): obj is LaravelValidationErrors => {
  return (
    typeof obj === "object" &&
    obj !== null &&
    typeof obj.message === "string" &&
    typeof obj.errors === "object" &&
    obj.errors !== null &&
    Object.values(obj.errors).every((val) => Array.isArray(val) && val.every((item) => typeof item === "string"))
  );
};

export const isTtrAnErrorResponse = (obj: any): obj is TtrAnErrorResponse => {
  return typeof obj === "object" && obj !== null && typeof obj.anError === "string";
};

export const getErrorMessageFromValidationError = (error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>, fallback: string): string => {
  const response = error.response;
  if (!response) {
    return fallback;
  }
  const data = response.data;
  if (isLaravelValidationErrors(data)) {
    return data.message;
  }
  if (isTtrAnErrorResponse(data)) {
    return data.anError;
  }
  return fallback;
};

export const getErrorsFromValidationError = (error: AxiosError<LaravelValidationErrors | TtrAnErrorResponse>): Record<string, string[]> => {
  const response = error.response;
  if (!response) {
    return {};
  }
  const data = response.data;
  if (isLaravelValidationErrors(data)) {
    return data.errors;
  }
  return {};
};

export {defaultAxios as axios, ownErrors as errors};
