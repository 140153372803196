export const BUCKETS_FOR_WHICH_TOTALS_CAN_BE_ADJUSTED: App.Enums.OptimizerWidgetId[] = [
  "TtrOptimizerInquiriesResolvedWidget",
  "TtrOptimizerInquiriesResolvedWithinTargetWidget",
  "TtrOptimizerFirstContactResolutionRateWidget",
  "TtrOptimizerConvosClosedWidget",
  "TtrOptimizerConvosAwaitingResponseWidget",
  "TtrOptimizerConvosSentInternallyWidget",
];

export const BUCKETS_WHICH_SHOW_CLOSED_EMAILS: App.Enums.OptimizerWidgetId[] = [
  "TtrOptimizerInquiriesResolvedWithinTargetWidget",
  "TtrOptimizerAvgTimeToCloseWidget",
  "TtrOptimizerFirstContactResolutionRateWidget",
  "TtrOptimizerConvosClosedWidget",
];

export const defaultCurrentThread = (): App.DataObjects.OptimizerData.OptimizerCurrentThreadData => ({
  id: null,
  type: "Unknown",
  status: "Unknown",
  firstReplyTime: "",
  messagesCount: 1,
  timeToClose: "",
  isBlocked: false,
  isSnoozed: false,
  isHidden: false,
  isClosed: false,
  messageId: null,
  isAssignedToMe: false,
  isAssignedToSomeoneElse: false,
  notes: [],
  isNull: true,
});

export const shouldShowPriorityEmail = (email: App.DataObjects.OptimizerData.OptimizerMessageData, showHidden: boolean): boolean => {
  return !email.is_snoozed && !email.is_assigned_to_others && !email.is_closed && (showHidden || !email.is_hidden);
};

export const shouldShowAssignedEmail = (email: App.DataObjects.OptimizerData.OptimizerMessageData, showHidden: boolean): boolean => {
  return !email.is_snoozed && !email.is_assigned_to_others && (showHidden || !email.is_hidden);
};

export const shouldShowStatDetailsEmail = (email: App.DataObjects.OptimizerData.OptimizerMessageData, stat: App.Enums.OptimizerWidgetId | null, showHidden: boolean): boolean => {
  if (stat === null) {
    return false;
  }
  if (!BUCKETS_FOR_WHICH_TOTALS_CAN_BE_ADJUSTED.includes(stat)) {
    return true;
  }
  if (email.is_snoozed) {
    return false;
  }
  if (email.is_assigned_to_others) {
    return false;
  }
  if (email.is_hidden && !showHidden) {
    return false;
  }

  //Some stats specifically show closed emails
  if (email.is_closed && BUCKETS_WHICH_SHOW_CLOSED_EMAILS.includes(stat)) {
    return true;
  }
  return !email.is_closed;
};

export const defaultOptimizerSettings = (): App.DataObjects.OptimizerData.OptimizerSettingsOnlyData => {
  return {
    conversation_actions: {
      enabled: false,
    },
    primary_widget: [],
    secondary_widgets: [],
    email_controls: {
      view: false,
      snooze: false,
      assign: false,
      close: false,
      hide: false,
    },
    important_emails: {
      enabled: false,
    },
  };
};

export const isResolvedAndTotalData = (
  value: App.DataObjects.OptimizerData.ResolvedAndTotalData | App.DataObjects.OptimizerData.ResolvedAndRemainingData | string | number | null | undefined,
): value is App.DataObjects.OptimizerData.ResolvedAndTotalData => {
  return typeof value === "object" && (value as App.DataObjects.OptimizerData.ResolvedAndTotalData).resolved !== undefined && (value as App.DataObjects.OptimizerData.ResolvedAndTotalData).total !== undefined;
};

export const isResolvedAndRemainingData = (
  value: App.DataObjects.OptimizerData.ResolvedAndTotalData | App.DataObjects.OptimizerData.ResolvedAndRemainingData | string | number | null | undefined,
): value is App.DataObjects.OptimizerData.ResolvedAndRemainingData => {
  return typeof value === "object" && (value as App.DataObjects.OptimizerData.ResolvedAndRemainingData).resolved !== undefined && (value as App.DataObjects.OptimizerData.ResolvedAndRemainingData).remaining !== undefined;
};

export const isNumber = (value: any): value is number => {
  return typeof value === "number";
};
