import TButton from "vue-tailwind/dist/t-button";

export default {
  component: TButton,
  props: {
    fixedClasses: "block transition duration-100 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed break-normal",
    classes: "px-4 py-2 border-2 bg-white dark:bg-gray-800 text-navy-900 dark:text-navy-200 border-navy-900 hover:bg-navy-900 dark:hover:bg-navy-50 hover:text-white dark:hover:text-navy-900 text-center rounded text-sm tracking-wider",
    variants: {
      primary:
        "px-4 py-2 border-2 bg-white dark:bg-gray-800 text-primary-500 dark:text-primary-200 border-primary-500 hover:bg-primary-500 dark:hover:bg-primary-50 hover:text-white dark:hover:text-primary-500 text-center rounded text-sm tracking-wider",
      success:
        "px-4 py-2 border-2 bg-success dark:bg-gray-800 text-navy-900 dark:text-success-200 border-success-500 hover:bg-white dark:hover:bg-success-50 hover:text-success-500 dark:hover:text-success-500 text-center rounded text-sm tracking-wider",
      danger:
        "px-4 py-2 border-2 bg-white dark:bg-gray-800 text-danger-500 dark:text-danger-200 border-danger-500 hover:bg-danger-500 dark:hover:bg-danger-50 hover:text-white dark:hover:text-danger-500 text-center rounded text-sm tracking-wider",
      warning:
        "px-4 py-2 border-2 bg-white dark:bg-gray-800 text-warning-500 dark:text-warning-200 border-warning-500 hover:bg-warning-500 dark:hover:bg-warning-50 hover:text-white dark:hover:text-warning-500 text-center rounded text-sm tracking-wider",
      inverseNavy: "px-4 py-2 border-2 bg-navy-900 dark:bg-white text-white dark:text-navy-900 border-navy-900 hover:bg-white dark:hover:bg-navy-900 hover:text-navy-900 dark:hover:text-white text-center rounded text-sm tracking-wider",
      navy: "px-4 py-2 border-2 bg-white dark:bg-gray-800 text-navy-900 dark:text-navy-200 border-navy-900 hover:bg-navy-900 dark:hover:bg-navy-50 hover:text-white dark:hover:text-navy-900 text-center rounded text-sm tracking-wider",
      "dropdown-item":
        "px-4 py-2 block w-full text-sm leading-5 text-gray-700 dark:text-gray-100 transition duration-150 ease-in-out hover:bg-gray-100 dark:bg-gray-900 focus:outline-none bg-white dark:bg-gray-800 focus:bg-gray-100 dark:bg-gray-900 text-left first:rounded-t-md last:rounded-b-md whitespace-nowrap",
      "dropdown-item-active":
        "px-4 py-2 block w-full text-sm leading-5 text-gray-700 dark:text-gray-100 transition duration-150 ease-in-out hover:bg-gray-100 dark:bg-gray-900 focus:outline-none bg-gray-200 dark:bg-gray-700 focus:bg-gray-100 dark:bg-gray-900 text-left first:rounded-t-md last:rounded-b-md whitespace-nowrap",
      icon: "bg-white dark:bg-gray-800 text-navy-900 dark:text-navy-200 hover:bg-navy-900 dark:hover:bg-navy-50 hover:text-white dark:hover:text-navy-900 rounded-full h-8 w-8 flex items-center justify-center",
    },
  },
};
